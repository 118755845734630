import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Typography, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import Report from "./../Report";

const styles = {
  title: {
    fontSize: 18,
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    textAlign: "center",
  },
  alignTitle: {
    display: "flex",
    justifyContent: "center"
  }
};

class ChartBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Abril",
          "Maio",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez"
        ],
        datasets: [
          {
            data: [
              20000,
              3000,
              15000,
              20000,
              3000,
              15000,
              20000,
              3000,
              15000,
              20012,
              40200,
              3000
            ],
            backgroundColor: ["rgba(213, 0, 12, 0.8)"]
          }
        ]
      }
    };
  }

  static defaultProps = {
    displayTittle: true,
    displayLegend: true,
    legendPosition: "top"
  };

  render() {
    const { classes, totalLeads } = this.props;
    return (
      <React.Fragment>
        <div className={classes.alignTitle}>
          <Typography
            className={classes.title}
            color="textSecondary"
          >
            {this.props.title}
          </Typography>
        </div>

        <Report legend="Total de Leads" value={totalLeads} />

        <HorizontalBar
          data={this.props.data}
          options={{
            responsive: true,
            legend: {
              display: false
            }
          }}
        />
      </React.Fragment>
    );
  }
}
export default compose(withStyles(styles))(ChartBar);
