import React from "react";
import { Router as ReachRouter } from "@reach/router";
import ListHomeScreen from "./../screens/HomeScreen";
import ListNewScreen from "./../screens/NewScreen";
import ListNewLeadScreen from "./../screens/NewLeadScreen";
import ListCalendarScreen from "./../screens/CalendarScreen";
import ListPipelineScreen from "./../screens/PipelineScreen";
import ListNotificationsScreen from "./../screens/NotifyScreen";
import ListReportScreen from "./../screens/ReportsScreen";
import ListSettingsScreen from "./../screens/SettingsScreen";
import ListImportLeadScreen from "./../screens/ImportLeadScreen";
import ListLeadsScreen from "./../screens/LeadsScreen";
import ListProfileScreen from "./../screens/ProfileScreen";
import ListScheduleScreen from "./../screens/ScheduleScreen";
import ListLoginScreen from "./../screens/LoginScreen";
import ListAboutScreen from "./../screens/SlideScreen";
import ListSplashScreen from "./../screens/SplashScreen";

export const Router = () => (
  <ReachRouter>
    <ListHomeScreen path="home" />
    <ListNewScreen path="/new/" />
    <ListNewLeadScreen path="/lead/" />
    <ListImportLeadScreen path="/import/" />
    <ListNewLeadScreen path="/lead/:id" />
    <ListCalendarScreen path="/calendar/" />
    <ListPipelineScreen path="/pipeline/" />
    <ListNotificationsScreen path="/notifications/" />
    <ListReportScreen path="/reports/" />
    <ListSettingsScreen path="/settings/" />
    <ListLeadsScreen path="/leads/" />
    <ListProfileScreen path="/profile/:id" />
    <ListScheduleScreen path="/schedule" />
  </ReachRouter>
);

export const RouterLogin = () => (
  <ReachRouter>
    <ListLoginScreen path="/login/" />
    <ListAboutScreen path="/about/" />
    <ListSplashScreen path="/" />
  </ReachRouter>
);
