import React from "react";
import styled from "styled-components";

const LabelSelectCSS = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: ${props => props.margin || "1rem 0"};
  margin-left: ${props => props.marginLeft || "0.5rem"};
`;

const LabelSelectTextCSS = styled.div`
  color: ${props => props.color || "#fff"};
  font-size: 1rem;
  display: flex;
  align-items: center;
  min-width: 70px;
`;

const LabelSelectFieldSelectCSS = styled.select`
  background: none;
  color: #fff;
  border: solid 1.5px;
  padding: 0.2rem 0.8rem;
  border-radius: 30px;
  margin-left: 0.5rem;
  outline: none;
  min-width: 168.5px;
  min-height: 29.38px;
  width: 100%;
`;

const LabelSelectFieldDateCSS = styled.input`
  background: ${props => props.dateBackground || "none"};
  color: ${props => props.color || "#fff"};
  border: ${props => props.dateBorder || "solid 1.5px"};
  padding: 0.2rem 0.8rem;
  border-radius: 30px;
  margin-left: 0.5rem;
  outline: none;
  min-width: 168.5px;
  min-height: 29.38px;
  width: ${props => props.dateWidth || "100%"};
`;

const LabelSelectFieldHourCSS = styled.input`
  background: none;
  color: #fff;
  border: solid 1.5px;
  padding: 0.2rem 0.8rem;
  border-radius: 30px;
  margin-left: 0.5rem;
  outline: none;
  min-width: 168.5px;
  min-height: 29.38px;
  width: 100%;
`;

const LabelDisabledFieldCSS = styled.div`
  background: none;
  color: #fff;
  border: solid 1.5px;
  padding: 0.2rem 0.8rem;
  border-radius: 30px;
  margin-left: 0.5rem;
  outline: none;
  min-width: 168.5px;
  min-height: 29.38px;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  border: none;
`;

const LabelSelectIconCSS = styled.i`
  margin-right: 0.3rem;
  color: ${props => props.color || "none"};
`;

class LabelSelect extends React.Component {
  render() {
    const {
      text,
      icon,
      type,
      value,
      onChange,
      disabled,
      defaultValue,
      selected,
      dateWidth,
      dateBorder,
      dateBackground,
      color,
      marginLeft,
      margin
    } = this.props;
    return (
      <LabelSelectCSS
        marginLeft={marginLeft}
        margin={margin}
        onChange={onChange}
        icon={icon}
        text={text}
      >
        <LabelSelectTextCSS color={color}>
          <LabelSelectIconCSS color={color}>{icon}</LabelSelectIconCSS>
          {text}
        </LabelSelectTextCSS>

        {type === "date" && (
          <LabelSelectFieldDateCSS
            disabled={disabled}
            color={color}
            value={value}
            onChange={onChange}
            dateBackground={dateBackground}
            dateBorder={dateBorder}
            dateWidth={dateWidth}
            type="date"
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
          />
        )}
        {type === "time" && (
          <LabelSelectFieldHourCSS
            disabled={disabled}
            value={value}
            onChange={onChange}
            type="time"
          />
        )}
        {type === "disabled" && (
          <LabelDisabledFieldCSS> {value} </LabelDisabledFieldCSS>
        )}
        {type === "select" && (
          <LabelSelectFieldSelectCSS
            defaultValue={defaultValue}
            selected={selected || false}
            disabled={disabled}
          >
            {this.props.children}
          </LabelSelectFieldSelectCSS>
        )}
      </LabelSelectCSS>
    );
  }
}

export default LabelSelect;
