import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TextParagraph = styled.p`
  color: ${props => props.color || "#454F63"};
  font-size: 0.9rem;
  line-height: 1.7;
  text-align: ${props => props.align || "left"};
  margin: 1rem 0;
`;

class Text extends React.Component {
  render() {
    const { content, align, color } = this.props;
    return (
      <TextParagraph color={color} align={align}>
        {content}
      </TextParagraph>
    );
  }
}

Text.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  content: PropTypes.string
};

export default Text;
