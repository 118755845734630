import React from "react";
import styled from "styled-components";

const DataListCSS = styled.div`
  display: "block";
  background: #f5f5f5;
  border-radius: 7px;
  position: absolute;
  top: 107%;
  width: 100%;
  font-size: 13px;
  max-height: 230px;
  overflow-y: scroll;
  z-index: 9;
`;

export const DataList = props => {
  return <DataListCSS>{props.children}</DataListCSS>;
};

export default DataList;
