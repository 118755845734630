import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Avatar from "../../components/Avatar";
import { Link } from "@reach/router";
import media from "styled-media-query";

const SimpleCardCSS = styled.div`
  padding: 1rem 1rem;
  border: solid 1px #cecece;
  border-radius: 16px;
  position: relative;
  margin: 0 0.3rem 1rem 0.3rem;
  ${media.greaterThan("740px")`
    border-color: #2A2E43;
    color: #2A2E43;
    margin: 0 1rem 1rem 1rem;
    width: 180px;
    line-height: 1.4;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const SimpleCardNameCSS = styled.p`
  font-weight: 600;
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  font-size: 0.9rem;
  max-width: 80%;
  ${media.greaterThan("740px")`
    max-width: 100%;
    text-align: center;
  `}
`;

const SimpleCardTextCSS = styled.p`
  font-size: 0.7rem;
  margin-top: 0.5rem;
  ${media.greaterThan("740px")`
    text-align: center;
  `}
`;

const SimpleCardTagCSS = styled.span`
  display: table;
  position: absolute;
  right: 20px;
  top: 21px;
  font-size: 0.8rem;
  border-left: solid 2px #ff2244;
  padding-left: 0.5rem;
`;

const BoldCSS = styled.strong`
  font-weight: 700;
`;

const SimpleCardContentCSS = styled.div`
  opacity: ${props => props.opacity || "1"};
`;

const SimpleCardCheckedCSS = styled.h1`
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-weight: 700;
  color: #009688;
`;
const BadgeCSS = styled.p`
  background: #4d97fd;
  display: table;
  color: #fff;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0.5rem;
  border-radius: 26px;
  margin: 0.5rem 0;
  position: absolute;
  left: 50%;
  top: -22px;
  transform: translateX(-50%);
  ${media.greaterThan("740px")`
    top: 40px;
    right: 17px;
    left: initial;
    transform: none;
  `}
`;

class SimpleCard extends React.Component {
  renderAvatar(avatar, name) {
    if (avatar !== false) {
      return <Avatar name={name} />;
    }
  }

  renderChecked(checked) {
    if (checked === true) {
      return <SimpleCardCheckedCSS>Concluido</SimpleCardCheckedCSS>;
    }
  }

  renderOrigin(media) {
    if (media) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Mídia:</BoldCSS> {media}
        </SimpleCardTextCSS>
      );
    }
  }

  renderCourse(course) {
    if (course) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Curso:</BoldCSS> {course}
        </SimpleCardTextCSS>
      );
    }
  }

  renderCampaign(campaign) {
    if (campaign) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Campanha:</BoldCSS> {campaign}
        </SimpleCardTextCSS>
      );
    }
  }
  renderOwner(owner) {
    if (owner) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Responsável:</BoldCSS> {owner}
        </SimpleCardTextCSS>
      );
    }
  }

  renderHour(hour) {
    if (hour) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Horário:</BoldCSS> {hour}
        </SimpleCardTextCSS>
      );
    }
  }

  renderDate(date) {
    if (date) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Data:</BoldCSS> {date}
        </SimpleCardTextCSS>
      );
    }
  }

  renderWhatsapp(cellphone) {
    function maskTel(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
      v = v.replace(/(\d)(\d{4})$/, "$1-$2");
      return v;
    }
    if (cellphone) {
      return (
        <SimpleCardTextCSS>
          <BoldCSS>Whatsapp:</BoldCSS> {maskTel(cellphone)}
        </SimpleCardTextCSS>
      );
    }
  }

  renderSimpleCard(
    onClick,
    opacity,
    avatar,
    name,
    media,
    horary,
    course,
    owner,
    tag,
    badge,
    disabled,
    style,
    cellphone,
    date,
    campaign
  ) {
    if (disabled) {
      return (
        <SimpleCardCSS onClick={onClick} style={style}>
          {this.renderChecked(true)}
          <SimpleCardContentCSS opacity="0.2">
            {this.renderAvatar(avatar, name)}
            <SimpleCardNameCSS>{name}</SimpleCardNameCSS>
            {this.renderWhatsapp(cellphone)}
            {this.renderOrigin(media)}
            {this.renderHour(horary)}
            {this.renderCourse(course)}
            {this.renderCampaign(campaign)}
            {this.renderOwner(owner)}
            {this.renderDate(date)}
            <SimpleCardTagCSS>{tag}</SimpleCardTagCSS>
            {badge && <BadgeCSS>{badge}</BadgeCSS>}
          </SimpleCardContentCSS>

          {this.props.children}
        </SimpleCardCSS>
      );
    }

    return (
      <SimpleCardCSS onClick={onClick} style={style}>
        {this.renderChecked(false)}
        <SimpleCardContentCSS opacity={opacity}>
          {this.renderAvatar(avatar, name)}
          <SimpleCardNameCSS>{name}</SimpleCardNameCSS>
          {this.renderWhatsapp(cellphone)}
          {this.renderOrigin(media)}
          {this.renderHour(horary)}
          {this.renderCourse(course)}
          {this.renderCampaign(campaign)}
          {this.renderOwner(owner)}
          {this.renderDate(date)}
          <SimpleCardTagCSS>{tag}</SimpleCardTagCSS>
          {badge && <BadgeCSS>{badge}</BadgeCSS>}
        </SimpleCardContentCSS>

        {this.props.children}
      </SimpleCardCSS>
    );
  }

  render() {
    const {
      name,
      link,
      media,
      course,
      owner,
      tag,
      avatar,
      horary,
      onclick,
      opacity,
      disabled,
      badge,
      style,
      cellphone,
      date,
      campaign,
      onClickLink,
      state
    } = this.props;

    return (
      <React.Fragment>
        {disabled ? (
          this.renderSimpleCard(
            onclick,
            opacity,
            avatar,
            name,
            media,
            horary,
            course,
            owner,
            tag,
            badge,
            disabled,
            style,
            cellphone,
            date,
            campaign
          )
        ) : (
          <Link
            onClick={onClickLink ? onClickLink : null}
            to={link ? link : "#"}
            state={{ idLead: state }}
          >
            {this.renderSimpleCard(
              onclick,
              opacity,
              avatar,
              name,
              media,
              horary,
              course,
              owner,
              tag,
              badge,
              disabled,
              style,
              cellphone,
              date,
              campaign
            )}
          </Link>
        )}
      </React.Fragment>
    );
  }
}

SimpleCard.propTypes = {
  name: PropTypes.string,
  city: PropTypes.string
};

export default SimpleCard;
