import React from "react";

const { Provider, Consumer } = React.createContext();

export class UIProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerCollapsed: props.isDrawerCollapsed,
      toggleDrawer: () =>
        this.setState({ isDrawerCollapsed: !this.state.isDrawerCollapsed }),
      id: "",
      setId: id => this.setState({ id }),
      name: "",
      setName: name => this.setState({ name }),
      idFranchise: 0,
      setIdFranchise: idFranchise => this.setState({ idFranchise }),
      franchiseName: "",
      setFranchiseName: franchiseName => this.setState({ franchiseName }),
      role: "",
      setRole: role => this.setState({ role }),
      idOwner: 0,
      setIdOwner: idOwner => this.setState({ idOwner }),
      isStageFranchise: false,
      setIsStageFranchise: isStageFranchise =>
        this.setState({ isStageFranchise }),
      isLoading: true,
      setIsLoading: isLoading => this.setState({ isLoading })
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export const withUIContext = Component => props => (
  <UIConsumer>
    {context => <Component {...props} uiContext={context} />}
  </UIConsumer>
);

export const UIConsumer = Consumer;
