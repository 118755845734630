import React from "react";
import styled from "styled-components";

const DataListItemCSS = styled.div`
  padding: 1rem;
  border-bottom: solid 1px #efefef;
  cursor: pointer;
`;

export const DataListItem = props => {
  return (
    <DataListItemCSS onClick={props.onClick}>{props.name}</DataListItemCSS>
  );
};

export default DataListItem;
