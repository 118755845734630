import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const EmptyCss = styled.p`
  text-align: center;
  display: block;
  margin: 1.5rem 0;
  font-size: 0.8rem;
  color:${props => props.color || "#444"};
  font-size: 1rem;
  margin: 3rem 0;
  ${media.greaterThan("740px")`
    color: #444;
  `}
`;

class Empty extends React.Component {
  render() {
    const { text, color } = this.props;
    return (
        <EmptyCss color={color}>
          {text}
        </EmptyCss>
    );
  }
}

export default Empty;