import React from "react";
import styled from "styled-components";

const ReportColorCSS = styled.div`
  border: 8px solid ${props => (props.color ? props.color : "#808080")};
  border-radius: 2px;
  margin-right: 16px;
`;

const ReportCSS = styled.div`
  border-radius: 10px;
  padding: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #000000;
`;

const ReportLegendCSS = styled.div`
  font-size: 1rem;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
`;

const ReportValueCSS = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #808080;
  font-family: Montserrat, sans-serif;
`;
const ALignReportColor = styled.div`
  display: flex;
  flex-direction: row;
`;

class Report extends React.Component {
  render() {
    const { legend, value, color } = this.props;
    return (
      <ReportCSS>
        <ALignReportColor>
         {color && <ReportColorCSS color={color} />}
          <ReportLegendCSS>{legend}</ReportLegendCSS>
        </ALignReportColor>
        <ReportValueCSS>{value}</ReportValueCSS>
      </ReportCSS>
    );
  }
}

export default Report;
