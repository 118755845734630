import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Chart from "chart.js";

const styles = {
  title: {
    fontSize: 18,
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    textAlign: "center",
    marginBottom: 16
  }
};

let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);

    let chart = this.chart;
    let width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;

    let fontSize = (height / 180).toFixed(2) + "em Montserrat, sans-serif";

    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    let text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 1.75;

    ctx.fillText(text, textX, textY);
  }
});

class ChartDoughnut extends Component {
  render() {
    const { classes, title } = this.props;
    return (
      <React.Fragment>
        <Typography
          letiant="display1"
          className={classes.title}
          color="textSecondary"
        >
          {title}
        </Typography>
        <Doughnut
          data={this.props.data}
          options={{
            responsive: true
          }}
        />
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ChartDoughnut);
