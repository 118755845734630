import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

const cache = new InMemoryCache();

const authLink = setContext(async (req, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-access-token": localStorage.getItem("token")
    }
  };
});

const error = onError(err => {
  console.log(err);
  if (
    err &&
    err.networkError &&
    (err.networkError.statusCode === 401 || err.networkError.statusCode === 403)
  ) {
    window.location.replace("/");
    localStorage.setItem("token", null);
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, error, httpLink])
});

export default client;
