import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Title from "./../Title";

const CalendarCSS = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;

  ${media.greaterThan("740px")`
        display: block;
        padding: 1rem;
    `}
`;

const CalendarDayCSS = styled.span`
  color: #ff2244;
  font-weight: 900;
  font-size: 2rem;
  ${media.greaterThan("740px")`
        display: block;
        padding: 1rem 0;
        margin-bottom: 1rem;
        position: relative;
        &::before {
            content: '';
            width: 90%;
            height: 2px;
            background: #ff2244;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    `}
`;

const CalendarFirstColCSS = styled.span`
  width: 17%;
  padding-top: 21px;
`;

const CalendarLastColCSS = styled.span`
  width: 83%;
`;

class Calendar extends React.Component {
  renderDay(day) {
    if (day) {
      return <CalendarDayCSS>{day}</CalendarDayCSS>;
    }
  }

  render() {
    const { day, month } = this.props;
    return (
      <React.Fragment>
        <Title margin="0" size="1.2rem" color="black" text={month} />
        <CalendarCSS>
          <CalendarFirstColCSS>{this.renderDay(day)}</CalendarFirstColCSS>
          <CalendarLastColCSS>{this.props.children}</CalendarLastColCSS>
        </CalendarCSS>
      </React.Fragment>
    );
  }
}

export default Calendar;
