import React from "react";
import styled from 'styled-components';

const CardActionCSS = styled.div`
    padding: 1rem 1.5rem;
    border-radius: 30px;
    box-shadow: 1px 4px 18px -11px rgba(0,0,0,0.46);
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
`;

class CardAction extends React.Component {

    render() {
        
        return (
            <CardActionCSS>
                { this.props.children }
            </CardActionCSS>
        );
    }
}

export default CardAction;