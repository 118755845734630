import {
    purple,
    red,
    pink,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey
  } from "@material-ui/core/colors";
  
  export const colors = [
    red[500],
    amber[500],
    green[500],
    cyan[500],
    deepOrange[500],
    orange[500],
    yellow[500],
    teal[500],
    indigo[500],
    lightBlue[500],
    lightGreen[500],
    lime[500],
    brown[500],
    purple[500],
    pink[500],
    deepPurple[500],
    blue[500],
    grey[500]
  ];
  