import React from "react";
import styled from "styled-components";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FileUploadIcon from "@material-ui/icons/Publish";
import Title from "./../../components/Title";
import { withStyles } from "@material-ui/core";
import { Link } from "@reach/router";

const CircleCSS = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ff2244;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-family: Montserrat;
  cursor: pointer;
`;

const AlignCirclesCSS = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const styles = {
  title: {
    paddingBottom: "40px"
  }
};

const New = (props) => {
  const { classes } = props;
  return (
    <React.Fragment>
      <AlignCirclesCSS>
        <Link to="/lead/">
          <CircleCSS>
            <PersonAddIcon fontSize="large" /> Adicionar contato
          </CircleCSS>
        </Link>

        <div className={classes.title}>
          <Title size="1rem" text="Manual" />
        </div>

        <Link to="/import/">
          <CircleCSS>
            <FileUploadIcon fontSize="large" />
            Importar contatos
          </CircleCSS>
        </Link>

        <Title size="1rem" text="Excel" />
      </AlignCirclesCSS>
    </React.Fragment>
  );
};

export default withStyles(styles)(New);
