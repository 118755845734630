import React from "react";
import styled from "styled-components";

const SettingCSS = styled.div`
  padding: 1rem;
  border: solid 1px #e8e8e8;
  border-radius: 12px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  position: relative;
`;

const SettingCheckedCSS = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

class Setting extends React.Component {
  render() {
    const { label, onclick } = this.props;

    return (
      <SettingCSS onClick={onclick}>
        <p>{label}</p>
        <SettingCheckedCSS>{this.props.children}</SettingCheckedCSS>
      </SettingCSS>
    );
  }
}

export default Setting;
