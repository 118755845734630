import React from "react";
import styled from "styled-components";

const BgModalCSS = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #000000a3;
  padding: 1rem;
`;

const ModalCSS = styled.div`
  padding: 1rem 1rem;
  background: #ffffff;
  border-radius: 30px;
  width: 100%;
  max-width: 790px;
  border: solid 1px #e2e1e1;
`;

class Modal extends React.Component {
  render() {
    return (
      <BgModalCSS>
        <ModalCSS>{this.props.children}</ModalCSS>
      </BgModalCSS>
    );
  }
}

export default Modal;
