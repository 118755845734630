import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SelectFieldCss = styled.select`
  border: solid 1px #cecece;
  outline: none;
  height: 45px;
  width: ${props => props.width || "100%"};
  display: block;
  border-radius: 10px;
  padding: 0 1rem;
  margin: 1.2rem 0;
  font-size: 0.8rem;
  position: ${props => props.position || "inherit"};
  top: ${props => props.top || "inherit"};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 40px;
  background: "#fff";
`;

class SelectField extends React.Component {
  render() {
    const { onChange, defaultValue, width } = this.props;
    return (
      <SelectFieldCss
        width={width}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {this.props.children}
      </SelectFieldCss>
    );
  }
}

SelectField.propTypes = {
  onChange: PropTypes.func
};

export default SelectField;
