import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TimeLineCSS = styled.div`
  display: block;
  padding: 1rem;
  margin: 1rem 1rem 0 1rem;
  border-left: dotted 2px #46506466;
`;

const TimeLineTitleCSS = styled.h2`
  font-weight: 600;
  font-size: 0.8rem;
`;

const TimeLineTextCSS = styled.p`
  padding: 0.7rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  background: #06060608;
  line-height: 1.5;
  margin-top: 0.5rem;
`;

const TimeLineTimeCSS = styled.p`
  padding: 0;
  font-size: 0.6rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: right;
  color: #1010108f;
`;
const TimeLineBadgeCSS = styled.p`
  background: #4d97fd;
  display: table;
  color: #fff;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0.5rem;
  border-radius: 26px;
  margin: 0.5rem 0;
`;

class TimeLine extends React.Component {
  render() {
    const { title, text, date, badge } = this.props;

    return (
      <TimeLineCSS>
        <TimeLineTitleCSS>{title}</TimeLineTitleCSS>
        {badge && <TimeLineBadgeCSS>{badge}</TimeLineBadgeCSS>}
        <TimeLineTextCSS>{text}</TimeLineTextCSS>
        <TimeLineTimeCSS>{date}</TimeLineTimeCSS>
      </TimeLineCSS>
    );
  }
}

TimeLine.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string
};

export default TimeLine;
