import React from "react";
import styled from "styled-components";
import Avatar from "../../components/Avatar";
import Home from "@material-ui/icons/Home";
import Today from "@material-ui/icons/Today";
import ViewWeek from "@material-ui/icons/ViewWeek";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Equalizer from "@material-ui/icons/Equalizer";
import Settings from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import media from "styled-media-query";
import { Link } from "@reach/router";

const MenuCSS = styled.div`
  padding: 2rem 1rem;
  position: absolute;
  left: 0;
  top: 0;
  background: #2a2e43;
  color: #fff;
  height: 100%;
  width: 90%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: left;
  z-index: 9;
  ${media.greaterThan("740px")`
    max-width: 310px;
    
  `}
`;

const MenuHeaderCSS = styled.div`
  display: flex;
  align-items: center;
`;

const MenuBodyCSS = styled.div`
  margin-top: 2rem;
`;

const MenuHeaderUserNameCSS = styled.div`
  max-width: 160px;
  margin-bottom: 0.4rem;
  line-height: 1.3;
`;

const MenuHeaderUserJobCSS = styled.div`
  font-size: 0.7rem;
  margin-top: 0.2rem;
`;

const MenuItemCSS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
  text-decoration: none;
`;

const MenuItemIconCSS = styled.span`
  position: relative;
  top: 1px;
  margin-right: 0.4rem;
`;

const MenuCloseCSS = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #fff;
  position: fixed;
  left: 76%;
  ${media.greaterThan("740px")`
    left: 250px;
  `}
`;

const AvatarMenu = {
  margin: "0 1rem 0 0"
};

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: "none"
    };
  }

  toggleMenu() {
    if (this.state.menu === "none") {
      this.setState({ menu: "block" });
    } else {
      this.setState({ menu: "none" });
    }
  }

  getOffice(role) {
    if (role === "manager") {
      return "Gerente";
    } else if (role === "telemarketing operator") {
      return "Operador de Telemarketing";
    } else if (role === "franchiseOwner") {
      return "Franqueado";
    } else if (role === "media assistant") {
      return "Assistente de Mídias";
    }
    return "Orientador";
  }

  handleLogout = () => {
    localStorage.setItem("token", null);
    window.location.replace("/");
  };

  render() {
    const { user, role } = this.props;

    return (
      <MenuCSS style={{ display: this.state.menu }}>
        <MenuCloseCSS onClick={() => this.toggleMenu()}>
          <MenuIcon />
        </MenuCloseCSS>
        <MenuHeaderCSS>
          <Avatar style={AvatarMenu} margin="0 1rem 0 0" name={user} />
          <div>
            <MenuHeaderUserNameCSS>{user}</MenuHeaderUserNameCSS>
            <MenuHeaderUserJobCSS>{this.getOffice(role)}</MenuHeaderUserJobCSS>
          </div>
        </MenuHeaderCSS>
        <MenuBodyCSS>
          <Link to="/home/" onClick={() => this.toggleMenu()}>
            <MenuItemCSS>
              <MenuItemIconCSS>
                <Home fontSize="small" />
              </MenuItemIconCSS>
              Home
            </MenuItemCSS>
          </Link>
          <Link to="/calendar/" onClick={() => this.toggleMenu()}>
            <MenuItemCSS>
              <MenuItemIconCSS>
                <Today fontSize="small" />
              </MenuItemIconCSS>
              Agendamentos
            </MenuItemCSS>
          </Link>
          <Link to="/pipeline/" onClick={() => this.toggleMenu()}>
            <MenuItemCSS>
              <MenuItemIconCSS>
                <ViewWeek fontSize="small" />
              </MenuItemIconCSS>
              Pipeline
            </MenuItemCSS>
          </Link>
          <Link to="/leads/" onClick={() => this.toggleMenu()}>
            <MenuItemCSS>
              <MenuItemIconCSS>
                <Person fontSize="small" />
              </MenuItemIconCSS>
              Contatos
            </MenuItemCSS>
          </Link>
            <Link to="/new/" onClick={() => this.toggleMenu()}>
              <MenuItemCSS>
                <MenuItemIconCSS>
                  <PersonAdd fontSize="small" />
                </MenuItemIconCSS>
                Novo Contato
              </MenuItemCSS>
            </Link>
          {(role === "manager" || role === "franchiseOwner") && (
            <React.Fragment>
              <Link to="/reports/" onClick={() => this.toggleMenu()}>
                <MenuItemCSS>
                  <MenuItemIconCSS>
                    <Equalizer fontSize="small" />
                  </MenuItemIconCSS>
                  Relatórios
                </MenuItemCSS>
              </Link>
              <Link to="/settings/" onClick={() => this.toggleMenu()}>
                <MenuItemCSS>
                  <MenuItemIconCSS>
                    <Settings fontSize="small" />
                  </MenuItemIconCSS>
                  Configurações
                </MenuItemCSS>
              </Link>
            </React.Fragment>
          )}
          <Link to="" onClick={() => this.handleLogout()}>
            <MenuItemCSS>Sair</MenuItemCSS>
          </Link>
        </MenuBodyCSS>
      </MenuCSS>
    );
  }
}

export default Menu;
