import React from "react";
import styled from "styled-components";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import media from "styled-media-query";

const ReminderCSS = styled.div`
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #2a2e43;
    ${media.greaterThan("740px")`
      position: relative;
      max-width: 800px;
      overflow: hidden;
      border-radius: 10px;
      margin: 0 auto;
      margin-bottom: 6rem;
      padding: 1rem;
      background: none;
      border: solid 1px #2a2e43;
    `}
`;

const ReminderHeaderCSS = styled.h1`
  padding: 1rem;
  background: #FF2244;
  color:#fff;
  text-align:center;
  font-weight:700;
  ${media.greaterThan("740px")`
    border-radius: 7px;
  `}
`;

const ReminderBodyCSS = styled.h1`
    padding: 1rem 1rem;
    background: #2A2E43;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${media.greaterThan("740px")`
      justify-content: center;
      background:none;
      color: #2A2E43;
    `}
`;

const ReminderBodyDayCSS = styled.p`
  font-weight: 700;
  color:#fff;
  font-size:1.0rem;
  ${media.greaterThan("740px")`
    color: #2A2E43;
    padding: 1rem 0;
  `}
`;

const ReminderBodyIconCSS = styled.div`
  color:#fff;
  ${media.greaterThan("740px")`
    display:none;
  `}
`;

const ReminderBodyFullCSS = styled.div`
  color:#fff;
  background: #2A2E43;
  overflow: scroll;
  ${media.greaterThan("740px")`
    height: auto!important;
    background: none;
    display: flex;
    justify-content: center;
    align-items:center;
    padding-bottom: 1rem;
    overflow: initial;
    flex-wrap: wrap;
  `}
`;

class Reminder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            height: "0px"
        };
    }

    toggleReminder() {
        if (this.state.height === "0px") {
            this.setState({ height: "300px" });
        } else {
            this.setState({ height: "0px" });
        }
    }

  render() {

    const { day } = this.props;

    return (
      <ReminderCSS>
        <ReminderHeaderCSS>Lembretes</ReminderHeaderCSS>
        <ReminderBodyCSS onClick={() => this.toggleReminder()}>
            <ReminderBodyIconCSS>
                <KeyboardArrowUp />
            </ReminderBodyIconCSS>
            <ReminderBodyDayCSS>{day}</ReminderBodyDayCSS>
        </ReminderBodyCSS>
        <ReminderBodyFullCSS style={{ height: this.state.height }}>
            {this.props.children}
        </ReminderBodyFullCSS>
      </ReminderCSS>
    );
  }
}

export default Reminder;
