import React from "react";
import styled from "styled-components";
import Avatar from "../../components/Avatar";
import Title from "../../components/Title";
import MetaInfo from "../../components/MetaInfo";
import BulletInfo from "../../components/BulletInfo";
import Tag from "../../components/Tag";
import Rate from "../../components/Rate";
import CardAction from "../../components/CardAction";
import CardButton from "../../components/CardButton";
import WhatsappImage from "../../assets/images/whatsapp-logo.png";
import PhoneImage from "../../assets/images/phone.png";
import ScheduleImage from "../../assets/images/icon-schedule.png";
import UserImage from "../../assets/images/icon-profile.png";
import HumanImage from "../../assets/images/icon-human.png";
import TimeLine from "../../components/TimeLine";
import ButtonStroke from "../../components/ButtonStroke";
import AddCircle from "@material-ui/icons/AddCircle";
import Modal from "../../components/Modal";
import FormGroup from "../../components/FormGroup";
import FormTitle from "../../components/FormTitle";
import FormFields from "../../components/FormFields";
import SelectField from "../../components/SelectField";
import AreaField from "../../components/AreaField";
import Button from "../../components/Button";
import LinkForm from "../../components/LinkForm";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { withUIContext } from "./../../contexts/UIContext";
import Loading from "./../../components/Loading";
import Badge from "./../../components/Badge";
import media from "styled-media-query";
import Swal from "sweetalert2";
import "moment/locale/pt-br";

const ProfileCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

const HeaderProfileCSS = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeaderBodyCSS = styled.div``;

class ProfileScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      rate: 0,
      leadDetail: {},
      allStages: {},
      value: 0,
      comment: "",
      idStageFranchise: 0,
      lastStage: "",
      lastStageFranchise: "",
      isLoading: false,
      idTag: 0,
      allTags: [],
      lastTag: "",
      stageFranchise: "",
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.leadDetails !== nextProps.leadDetails &&
      nextProps.leadDetails
    ) {
      const lastStageFranchise =
        (nextProps.leadDetails.findLeadById &&
          nextProps.leadDetails.findLeadById.leadStageComment.length &&
          nextProps.leadDetails.findLeadById.leadStageComment[
            nextProps.leadDetails.findLeadById.leadStageComment.length - 1
          ].stageFranchise &&
          nextProps.leadDetails.findLeadById.leadStageComment[
            nextProps.leadDetails.findLeadById.leadStageComment.length - 1
          ].stageFranchise.stage.name) ||
        "";

      const lastTag =
        (nextProps.leadDetails.findLeadById &&
          nextProps.leadDetails.findLeadById.leadStageComment.length &&
          nextProps.leadDetails.findLeadById.leadStageComment[
            nextProps.leadDetails.findLeadById.leadStageComment.length - 1
          ].tag &&
          nextProps.leadDetails.findLeadById.leadStageComment[
            nextProps.leadDetails.findLeadById.leadStageComment.length - 1
          ].tag.name) ||
        "";

      const rate =
        (nextProps.leadDetails.findLeadById &&
          nextProps.leadDetails.findLeadById.rate) ||
        0;

      this.setState({
        leadDetail: { ...nextProps.leadDetails.findLeadById } || {},
        lastStageFranchise,
        lastTag,
        rate,
      });
    }
    if (
      this.props.allStagesFranchise !== nextProps.allStagesFranchise &&
      nextProps.allStagesFranchise
    ) {
      this.setState({
        allStages:
          {
            ...nextProps.allStagesFranchise,
          } || {},
      });
    }

    if (
      this.props.allTagsByIdStage !== nextProps.allTagsByIdStage &&
      nextProps.allTagsByIdStage
    ) {
      this.setState({
        allTags: {
          ...nextProps.allTagsByIdStage,
        },
      });
    }
  }

  async handleSave() {
    if (!this.state.stageFranchise || this.state.stageFranchise === "") {
      return Swal.fire({
        title: "Ops!",
        text: "Por favor, insira o status",
        type: "error",
        confirmButtonText: "Ok",
      });
    }

    this.setState({ isLoading: true });

    const stageFranchiseObj = JSON.parse(this.state.stageFranchise) || "";

    await this.props.createLeadStageComment({
      variables: {
        idStageFranchise: stageFranchiseObj.id,
        idLead: this.props.id,
        idTag: this.state.idTag || null,
        comment: this.state.comment,
      },
    });

    this.setState({ modal: false, idStageFranchise: 0, comment: "", idTag: 0 });

    this.props.leadDetails.refetch();
    document.body.style.overflow = "initial";
    this.setState({ isLoading: false });
  }

  handleModal() {
    if (this.state.modal === false) {
      this.setState({
        modal: true,
      });

      document.body.style.overflow = "hidden";
    } else {
      this.setState({
        modal: false,
      });

      document.body.style.overflow = "initial";
    }
  }
  renderLeadDetails(name, media, _, course, lastStageFranchise, rate, lastTag) {
    return (
      <React.Fragment>
        <Avatar
          float="none"
          margin="0 0 2rem 0"
          width="100%"
          height="200px"
          radius="0"
          name={name || ""}
        />
        <HeaderProfileCSS>
          <div>
            <Title
              margin="0 0 .5rem 0"
              align="left"
              size="1.2rem"
              color="black"
              text={name}
            />
            <MetaInfo label="Mídia" value={media} />
            <BulletInfo value={course} />
          </div>

          <div>
            <Tag text={lastStageFranchise} />
            <Rate value={rate} />

            {lastTag && <Badge text={lastTag} margin="0 0 0 auto" />}
          </div>
        </HeaderProfileCSS>
      </React.Fragment>
    );
  }

  renderActions(whatsapp) {
    return (
      <React.Fragment>
        <CardAction>
          {whatsapp && (
            <React.Fragment>
              <CardButton
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${whatsapp}`
                  )
                }
                image={WhatsappImage}
                text="Whatsapp"
              />

              <CardButton
                text="Ligação"
                image={PhoneImage}
                type="tel"
                whatsNumber={whatsapp}
              />
            </React.Fragment>
          )}
          {this.props.uiContext.role !== "franchiseOwner" && (
            <CardButton
              link={"/schedule"}
              state={parseInt(this.props.id)}
              image={ScheduleImage}
              text="Agenda"
            />
          )}
          <CardButton
            link={`/lead/${this.props.id}`}
            image={UserImage}
            text="Editar"
          />
        </CardAction>
      </React.Fragment>
    );
  }

  renderLeadHistoric(historicLead) {
    return (
      <React.Fragment>
        <Title
          image={HumanImage}
          margin="2rem 0"
          align="left"
          size="1.2rem"
          color="black"
          text="Histórico"
        />

        {historicLead &&
          historicLead.map((historic, index) => (
            <TimeLine
              key={index}
              title={
                historic.stageFranchise && historic.stageFranchise.stage.name
              }
              badge={(historic.tag && historic.tag.name) || ""}
              text={historic.comment}
              date={moment(historic.createdAt).format("DD/MM/YYYY HH:mm")}
            />
          ))}

        <ButtonStroke onClick={() => this.handleModal()}>
          <AddCircle fontSize="small" />
        </ButtonStroke>
      </React.Fragment>
    );
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });

    const stageFranchiseObj = JSON.parse(event.target.value);

    this.props.allTagsByIdStage.refetch({
      idStage:
        (stageFranchiseObj.stage && parseInt(stageFranchiseObj.stage.id)) || 0,
    });
  };

  renderModal(stageFranchise, allTags) {
    return (
      <React.Fragment>
        <Modal>
          {!this.state.isLoading ? (
            <form>
              <FormGroup>
                <FormTitle text="Novo Histórico" />
                <FormFields>
                  <SelectField
                    onChange={this.handleChange("stageFranchise")}
                    value={stageFranchise}
                  >
                    <option value={0}>Qual o status?</option>
                    {this.state.allStages &&
                      this.state.allStages.findAllStageFranchiseByIdFranchise.map(
                        (franchiseStage, index) => (
                          <option
                            key={index}
                            value={JSON.stringify(franchiseStage)}
                          >
                            {franchiseStage.stage.name}
                          </option>
                        )
                      )}
                  </SelectField>

                  {!allTags.findAllTagsByIdStage.length ? (
                    ""
                  ) : this.props.allTagsByIdStage.loading ? (
                    <Loading />
                  ) : (
                    <SelectField
                      onChange={(e) => this.setState({ idTag: e.target.value })}
                      value={this.state.idTag}
                    >
                      <option value={0}>Qual a tag?</option>
                      {this.state.allStages &&
                        this.state.allTags.findAllTagsByIdStage.map(
                          (tag, index) => (
                            <option key={index} value={tag.id}>
                              {tag.name}
                            </option>
                          )
                        )}
                    </SelectField>
                  )}

                  <AreaField
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    value={this.state.comment}
                    label="Descrição"
                  />
                  <Button
                    onClick={this.handleSave}
                    width="100%"
                    color="#FF2244"
                  >
                    Salvar
                  </Button>
                  <LinkForm
                    disabledLink
                    onClick={() => this.handleModal()}
                    margin="1.5rem 0 0 0"
                    text="Cancelar"
                  />
                </FormFields>
              </FormGroup>
            </form>
          ) : (
            <Loading />
          )}
        </Modal>
      </React.Fragment>
    );
  }

  render() {
    const isModal = this.state.modal;
    const _ = "";

    return (
      <React.Fragment>
        {!this.props.leadDetails.loading ? (
          <ProfileCSS>
            {this.renderLeadDetails(
              this.state.leadDetail.name,
              (this.state.leadDetail.media &&
                this.state.leadDetail.media.name) ||
                "desconhecido",
              _,
              (this.state.leadDetail.course &&
                this.state.leadDetail.course.name) ||
                "desconhecido",
              this.state.lastStageFranchise,
              this.state.rate,
              this.state.lastTag
            )}
            <HeaderBodyCSS>
              {this.renderActions(this.state.leadDetail.cellphone)}

              {this.renderLeadHistoric(this.state.leadDetail.leadStageComment)}
            </HeaderBodyCSS>

            {isModal &&
              this.renderModal(this.state.stageFranchise, this.state.allTags)}
          </ProfileCSS>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

const createLeadStageComment = gql`
  mutation createLeadStageComment(
    $idStageFranchise: ID!
    $idLead: ID!
    $idTag: ID
    $comment: String
  ) {
    createLeadStageComment(
      idStageFranchise: $idStageFranchise
      idLead: $idLead
      comment: $comment
      idTag: $idTag
    )
  }
`;

const queryFindLeadById = gql`
  query queryFindLeadById($id: ID!) {
    findLeadById(id: $id) {
      id
      name
      idMedia
      idCourse
      media {
        id
        name
      }
      course {
        id
        name
      }
      rate
      cellphone
      idStageFranchise
      stageFranchise {
        id
        stage {
          id
          name
        }
      }
      leadStageComment {
        id
        comment
        createdAt
        stageFranchise {
          id
          stage {
            id
            name
          }
        }
        tag {
          id
          name
        }
      }
    }
  }
`;

const queryFindAllStageFranchiseByIdFranchise = gql`
  query queryFindAllStageFranchiseByIdFranchise($idFranchise: ID!) {
    findAllStageFranchiseByIdFranchise(idFranchise: $idFranchise) {
      id
      stage {
        id
        name
      }
    }
  }
`;

const queryFindAllTagsByIdStage = gql`
  query queryFindAllTagsByIdStage($idStage: ID) {
    findAllTagsByIdStage(idStage: $idStage) {
      id
      name
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindLeadById, {
    name: "leadDetails",
    options: (props) => ({
      variables: {
        id: props.id || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllStageFranchiseByIdFranchise, {
    name: "allStagesFranchise",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllTagsByIdStage, {
    name: "allTagsByIdStage",
    options: {
      variables: {
        idStage: 0,
      },
      fetchPolicy: "cache-and-network",
    },
  }),

  graphql(createLeadStageComment, { name: "createLeadStageComment" })
)(ProfileScreen);
