import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const AreaFieldCSS = styled.textarea`
  border: none;
  outline: none;
  height: 95px;
  width: 100%;
  display: block;
  border-radius: 10px;
  padding: 1rem;
  border: solid 1px #cecece;
  margin: 1.2rem 0;
  font-size: .8rem;
  position: ${props => props.position || "inherit"};
  top: ${props => props.top || "inherit"};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 40px;
`;

class AreaField extends React.Component {
  render() {
    const { label, position, top, icon, onChange, value } = this.props;
    return (
      <AreaFieldCSS
        onChange={onChange}
        value={value}
        icon={icon}
        position={position}
        top={top}
        placeholder={label}
      />
    );
  }
}

AreaField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  top: PropTypes.string,
  position: PropTypes.string,
  icon: PropTypes.string
};

export default AreaField;
