import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonStrokeCSS = styled.button`
  background: none;
  border: solid 2px;
  padding: 0.2rem 1rem;
  padding-top: 6px;
  border-radius: 30px;
  color: #009688;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  display: block;
  margin: ${props => props.margin || "1rem 0 0 0"};
`;

class ButtonStroke extends React.Component {
  render() {
    const { onClick, margin } = this.props;

    return (
      <ButtonStrokeCSS onClick={onClick} margin={margin}>
        {this.props.children}
      </ButtonStrokeCSS>
    );
  }
}

ButtonStroke.propTypes = {
  onClick: PropTypes.func
};

export default ButtonStroke;
