import React from "react";
import styled from "styled-components";

const FormFieldsCSS = styled.div`
  width: 100%;
  padding: 0 1rem;
`;

class FormFields extends React.Component {
  render() {
    return <FormFieldsCSS>{this.props.children}</FormFieldsCSS>;
  }
}

export default FormFields;
