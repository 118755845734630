import React from "react";
import styled from "styled-components";

const DotsCSS = styled.div`
  display: flex;
  align-items: center;
`;

const DotCSS = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #78849e;
  opacity: 0.32;
  margin-right: 5px;
`;

const DotCurrentCSS = styled(DotCSS)`
  background: red;
  opacity: 1;
`;

class Dots extends React.Component {
  renderDots() {
    let dots = [];
    for (let i = 0; i < this.props.total; i++) {
      if (i !== this.props.current) {
        dots.push(<DotCSS key={i} />);
      } else {
        dots.push(<DotCurrentCSS key={i} />);
      }
    }

    return dots;
  }

  render() {
    return <DotsCSS>{this.renderDots()}</DotsCSS>;
  }
}

export default Dots;
