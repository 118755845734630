import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import media from "styled-media-query";

const MetroMenuItemCSS = styled(Link)`
  width: 50%;
  background: ${props => props.color || "#FF2244"};
  padding: 3rem 1rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${media.greaterThan("740px")`
      width: 30%;
      margin: 1%;
      border-radius: 10px;
    `}
`;

const MetroMenuItemIconCSS = styled.div`
  margin-bottom: 0.5rem;
`;

class MetroMenuItem extends React.Component {
  render() {
    const { color, link, icon, text } = this.props;

    return (
      <MetroMenuItemCSS to={link} color={color}>
        <MetroMenuItemIconCSS>{icon}</MetroMenuItemIconCSS>
        {text}
      </MetroMenuItemCSS>
    );
  }
}

export default MetroMenuItem;
