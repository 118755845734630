import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const MenuMetroCSS = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 118px;
  background: #710f1e;

  ${media.greaterThan("740px")`
      background: none;
      padding: 6rem 0;
      max-width: 820px;
      margin: 0 auto;
    `}
`;

class MetroMenu extends React.Component {
  render() {
    return <MenuMetroCSS>{this.props.children}</MenuMetroCSS>;
  }
}

export default MetroMenu;
