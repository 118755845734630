import React, { Component } from "react";
import styled from "styled-components";

const AlignProgressCSS = styled.div`
  width: 100%;
  height: 8px;
  background-color: #ff2244;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-self: center;
`;

const ProgressCSS = styled.div`
  background-color: #ff2244;
  height: 100%;
  margin: 0;
  border-radius: 5px;

`;

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <AlignProgressCSS>
        <ProgressCSS style={{ width: this.props.progress + "%" }} />
      </AlignProgressCSS>
    );
  }
}

export default Progress;
