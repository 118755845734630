import React from "react";
import styled from "styled-components";
import Slide from "../../components/Slide";
import imgSlide_1 from "../../assets/images/slide-1.png";
import imgSlide_2 from "../../assets/images/slide-2.png";
import imgSlide_3 from "../../assets/images/slide-3.png";
import imgSlide_4 from "../../assets/images/slide-4.png";

const SlideWrapper = styled.div`
  padding: 1.5rem;
`;

class SlideScreen extends React.Component {
  render() {
    let slideObject = {
      0: {
        image: imgSlide_1,
        title: "O que é um CRM?",
        text:
          "Customer Relationship Management (ou Gestão do Relacionamento com o Cliente, em português) – é uma abordagem que coloca o cliente como principal foco dos processos de negócio, com o intuito de perceber e antecipar suas necessidades, para então atendê-los da melhor forma."
      },
      1: {
        image: imgSlide_2,
        title: "Operacional",
        text:
          "Bem mais que apenas atender com rapidez seus clientes e resolver seus problemas, é por meio desse tipo de aplicação operacional que se descobrem inconformidades nos processos; funcionalidades de seus serviços ou características de seus produtos que precisam ser melhoradas e, na verdade, até sugestões importantes que, posteriormente, poderão ser usadas no desenvolvimento de novas soluções para seus clientes."
      },
      2: {
        image: imgSlide_3,
        title: "Analítica",
        text:
          "Com todos os dados obtidos, é possível gerar relatórios e análises para que se possa traçar perfis de clientes e identificar suas necessidades. Assim,o contato com os clientes pode ser cada vez mais próximo e personalizado, possibilitando ao vendedor se preparar muito mais para suas ações de vendas e permitindo que ele ofereça aos compradores exatamente o que procuram,  atendendo seus requisitos de preço, forma de pagamento, canal de vendas e momento de compra."
      },
      3: {
        image: imgSlide_4,
        title: "Colaborativa",
        text:
          "A aplicação colaborativa trata de fazer com que a informação flua de todos os pontos de contato e relacionamento com o cliente e se dissemine pela empresa e seus agentes, criando a colaboração necessária para que o foco no atendimento das necessidades dos clientes se concretize."
      }
    };

    return (
      <SlideWrapper>
        <Slide slide={slideObject} />
      </SlideWrapper>
    );
  }
}

export default SlideScreen;
