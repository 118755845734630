import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HeaderImg = styled.img`
  display: block;
  margin: 0 auto;
`;

class Brand extends React.Component {
  render() {
    const { image } = this.props;
    return <HeaderImg src={image} />;
  }
}

Brand.propTypes = {
  image: PropTypes.string
};

export default Brand;
