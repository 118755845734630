import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TextTitle = styled.h1`
  color: ${props => props.color || "#454F63"};
  text-align: ${props => props.align || "left"};
  font-size: ${props => props.size || "1.3rem"};
  padding: 0 0rem;
  margin: ${props => props.margin || "1rem 0 1.5rem 0"};
  display: flex;
  align-items: center;
`;

const TextTitleImage = styled.img`
  margin-right: 0.7rem;
`;

class Title extends React.Component {
  render() {
    const { text, color, align, size, margin, image } = this.props;
    return (
      <TextTitle margin={margin} align={align} size={size} color={color}>
        {image ? <TextTitleImage src={image} /> : ""}
        {text}
      </TextTitle>
    );
  }
}

Title.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string,
  image: PropTypes.string
};

export default Title;
