import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Report from "../../components/Report";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import Loading from "./../../components/Loading";
import media from "styled-media-query";
import ChartDoughnut from "./../../components/ChartDoughnut";
import ChartBarHorizontal from "./../../components/ChartBarHorizontal";
import LabelSelect from "./../../components/LabelSelect";
import Event from "@material-ui/icons/Event";
import { colors } from "./../../util/styles/color";
import moment from "moment";
import Empty from "./../../components/Empty";

const ReportsCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

const AlignActionsDateCSS = styled.div`
  margin-bottom: 40px;
  ${media.greaterThan("620px")` 
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 40px;
  `}
`;

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      initialDate: moment().format("YYYY-MM-DD"),
      finalDate: moment()
        .add(7, "days")
        .format("YYYY-MM-DD")
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialDate, finalDate } = this.state;

    if (
      prevProps.reportLeadsPerPipeline.loading &&
      !this.props.reportLeadsPerPipeline.loading &&
      this.props.reportLeadsPerPipeline
    ) {
      this.setState({
        totalLeads:
          this.props.reportLeadsPerPipeline
            .findTotalLeadsPerStageFranchiseByIdFranchise.totalLeads || 0,
        data: {
          ...this.props.reportLeadsPerPipeline,

          reportLeads: {
            labels: this.props.reportLeadsPerPipeline.findTotalLeadsPerStageFranchiseByIdFranchise.totalLeadsStageFranchise.map(
              item => item.stage.name
            ),
            datasets: [
              {
                data: this.props.reportLeadsPerPipeline.findTotalLeadsPerStageFranchiseByIdFranchise.totalLeadsStageFranchise.map(
                  item => item.totalLeads
                ),
                backgroundColor: this.props.reportLeadsPerPipeline.findTotalLeadsPerStageFranchiseByIdFranchise.totalLeadsStageFranchise.map(
                  (_, index) => colors[index]
                )
              }
            ],
            text: `${this.props.reportLeadsPerPipeline.findTotalLeadsPerStageFranchiseByIdFranchise.totalLeads} Leads`
          }
        }
      });
    }

    if (
      this.props.reportLeadsPerPipeline.variables.initialDate !==
        this.state.initialDate ||
      this.props.reportLeadsPerPipeline.variables.finalDate !==
        this.state.finalDate
    ) {
      this.props.reportLeadsPerPipeline.refetch({ initialDate, finalDate });
    }
  }

  handleChange = name => async event => {
    this.setState({ [name]: event.target.value });
  };

  renderReports(totalLeads, data) {
    if (!totalLeads) {
      return (
        <Empty
          color="#fff"
          text="Nenhum lead foi encontrado para este relatório"
        />
      );
    }
    return (
      <React.Fragment>
        {this.props.uiContext.windowWidth < 480 ? (
          <React.Fragment>
            <ChartBarHorizontal
              title="Leads por pipeline"
              data={data.reportLeads}
              totalLeads={totalLeads}
            />
          </React.Fragment>
        ) : (
          <ChartDoughnut data={data.reportLeads} title="Leads por pipeline" />
        )}
        {data.reportLeads &&
          data.reportLeads.labels.map((label, index) => (
            <Report
              key={index}
              legend={label}
              color={colors[index]}
              value={
                this.state.data.findTotalLeadsPerStageFranchiseByIdFranchise
                  .totalLeadsStageFranchise[index].percentLeadPerStage
              }
            />
          ))}
      </React.Fragment>
    );
  }

  render() {
    const { data, totalLeads } = this.state;

    return (
      <React.Fragment>
        <ReportsCSS>
          <Title align="center" size="2rem" color="black" text="Relatórios" />

          <AlignActionsDateCSS>
            <LabelSelect
              type="date"
              dateBackground="#cecece"
              color="black"
              dateBorder="solid 1.5px #cecece"
              icon={<Event fontSize="small" />}
              text="Inicial:"
              value={this.state.initialDate}
              onChange={this.handleChange("initialDate")}
            />
            <LabelSelect
              type="date"
              dateBackground="#cecece"
              color="black"
              dateBorder="solid 1.5px #cecece"
              icon={<Event fontSize="small" />}
              text="Final:"
              value={this.state.finalDate}
              onChange={this.handleChange("finalDate")}
            />
          </AlignActionsDateCSS>

          {this.props.reportLeadsPerPipeline.loading ? (
            <Loading />
          ) : (
            this.renderReports(totalLeads, data)
          )}
        </ReportsCSS>
      </React.Fragment>
    );
  }
}

const queryFindTotalLeadsPerStageFranchiseByIdFranchise = gql`
  query queryFindTotalLeadsPerStageFranchiseByIdFranchise(
    $idFranchise: ID
    $idOwner: ID
    $initialDate: String!
    $finalDate: String!
  ) {
    findTotalLeadsPerStageFranchiseByIdFranchise(
      idFranchise: $idFranchise
      idOwner: $idOwner
      initialDate: $initialDate
      finalDate: $finalDate
    ) {
      totalLeads
      totalLeadsStageFranchise {
        totalLeads
        percentLeadPerStage
        stage {
          name
        }
      }
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindTotalLeadsPerStageFranchiseByIdFranchise, {
    name: "reportLeadsPerPipeline",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        idOwner: props.uiContext.idOwner || "",
        initialDate: moment().format("YYYY-MM-DD"),
        finalDate: moment()
          .add(7, "days")
          .format("YYYY-MM-DD")
      },
      fetchPolicy: "cache-and-network"
    })
  })
)(Reports);
