import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "@reach/router";
// import { Link } from "react-router-dom";

const CardButtonCSS = styled.div`
  display: block;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.7;
`;

const CardLinkCSS = styled.a`
  display: block;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.7;
`;

const CardButtonImageCSS = styled.img`
  height: 20px;
`;

const CardButtonTextCSS = styled.p``;

class CardButton extends React.Component {
  render() {
    const { image, text, link, state, onClick, type, whatsNumber } = this.props;

    return (
      <React.Fragment>
        {type === "tel" ? (
          <CardLinkCSS href={`tel:${whatsNumber}`} onClick={onClick}>
            <CardButtonImageCSS src={image} />
            <CardButtonTextCSS>{text}</CardButtonTextCSS>
          </CardLinkCSS>
        ) : (
          <Link to={link ? link : "#"} state={{ idLead: state }}>
            <CardButtonCSS onClick={onClick}>
              <CardButtonImageCSS src={image} />
              <CardButtonTextCSS>{text}</CardButtonTextCSS>
            </CardButtonCSS>
          </Link>
        )}
      </React.Fragment>
    );
  }
}

CardButton.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string
};

export default CardButton;
