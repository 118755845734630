import React from "react";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import Notifications from "@material-ui/icons/Notifications";
import Menu from "../../components/Menu";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import { Link } from "@reach/router";
import media from "styled-media-query";
import { Router } from "./../../routes";

const CityContent = styled.div`
  height: 40px;
  background-color: #d3d3d3;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

const HeaderCSS = styled.div`
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: #fbfbfb;
  ${media.greaterThan("740px")`
    background: #a3142a;
    padding: 1.5rem 1rem;    
  `}
`;

const AlignHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderIconMenuCSS = styled.button`
  background: none;
  border: none;
  padding: 0;
  outline: none;
  ${media.greaterThan("740px")`
    color: #fff;
  `}
`;

const HeaderUserCSS = styled.div``;

const HeaderNotifyCSS = styled(Link)`
  position: relative;
  background: none;
  border: none;
  text-decoration: none;
  ${media.greaterThan("740px")`
    color: #fff;
  `}
`;

const NotifyBulletCSS = styled.span`
  position: absolute;
  border-radius: 100%;
  background: #3fb58f;
  color: #fff;
  font-weight: 700;
  width: 18px;
  height: 18px;
  left: 12px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
`;

const HeaderUserNameCSS = styled.select`
  border: none;
  position: relative;
  left: 10px;
  outline: none;
  background: #fff;
  ${media.greaterThan("740px")`
    height: 40px;
    border-radius: 10px;
    padding: 0 17px;
    font-size: 14px;
    font-weight: 600;
  `}
`;

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newleads: 0,
      name: "",
      colaborators: [],
      idOwner: 0,
      role: "",
      idFranchise: 0,
      franchiseName: ""
    };

    this.Menu = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.loading !== this.props.user.loading &&
      this.props.user.findUserByToken
    ) {
      const { id, name, role, idFranchise } = this.props.user.findUserByToken;

      const franchiseName = this.props.user.findUserByToken.franchise.name;

      if (role === "owner" || role === "telemarketing operator") {
        this.props.uiContext.setIdOwner(id);
      }

      this.props.uiContext.setIsLoading(true);
      this.props.uiContext.setName(name);
      this.props.uiContext.setRole(role);
      this.props.uiContext.setId(id);
      this.props.uiContext.setFranchiseName(franchiseName);

      if (idFranchise !== null || idFranchise !== 0) {
        this.props.uiContext.setIdFranchise(idFranchise);
      }

      this.props.uiContext.setIsLoading(false);

      this.setState({
        name,
        role,
        idFranchise,
        franchiseName
      });
    }
  }

  getOffice(role) {
    if (role === "manager") {
      return "Gerente";
    } else if (role === "telemarketing operator") {
      return "Operador de Telemarketing";
    } else if (role === "franchiseOwner") {
      return "Franqueado";
    } else if (role === "media assistant") {
      return "Assistente de Mídias";
    } else if (role === "owner") {
      return "Orientador";
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      this.props.colaborators !== nextProps.colaborators &&
      nextProps.colaborators
    ) {
      const colaborators = nextProps.colaborators;
      this.setState({ colaborators });
    }
    if (
      this.props.withoutOwner !== nextProps.withoutOwner &&
      nextProps.withoutOwner.findAllLeadsWithoutOwner
    ) {
      this.setState({
        newleads: nextProps.withoutOwner.findAllLeadsWithoutOwner.totalLeads
      });
    }
    if (
      !this.props.uiContext.isStageFranchise &&
      this.props.uiContext.idFranchise !== nextProps.uiContext.idFranchise &&
      nextProps.uiContext.idFranchise
    ) {
      await this.props.createStageFranchise({
        variables: {
          idFranchise: nextProps.uiContext.idFranchise
        }
      });

      this.props.uiContext.setIsStageFranchise(true);
    }
  }

  handleLogout = () => {
    localStorage.setItem("token", null);
    window.location.replace("/");
  };

  toggleMenu() {
    this.Menu.current.toggleMenu();
  }

  getNotifications(newleads) {
    if (newleads > 0) {
      return (
        <NotifyBulletCSS>{newleads > 99 ? "99+" : newleads}</NotifyBulletCSS>
      );
    }
  }

  renderHeader(role, franchiseName) {
    return (
      <React.Fragment>
        <AlignHeader>
          <CityContent>
            {this.getOffice(role)} - {franchiseName}
          </CityContent>
          <HeaderCSS>
            <HeaderIconMenuCSS onClick={() => this.toggleMenu()}>
              <MenuIcon />
            </HeaderIconMenuCSS>
            {(role === "manager" ||
              role === "franchiseOwner" ||
              role === "media assistant") && (
              <React.Fragment>
                <HeaderUserCSS>
                  <HeaderUserNameCSS
                    onChange={e =>
                      this.props.uiContext.setIdOwner(parseInt(e.target.value))
                    }
                    value={this.props.uiContext.idOwner}
                  >
                    <option value={0}>Geral</option>
                    {this.state.colaborators.findAllOwnersByIdFranchise &&
                      this.state.colaborators.findAllOwnersByIdFranchise.map(
                        (colaborator, index) => (
                          <option key={index} value={colaborator.id}>
                            {colaborator.name}
                          </option>
                        )
                      )}
                  </HeaderUserNameCSS>
                </HeaderUserCSS>

                <HeaderNotifyCSS to="/notifications/">
                  <Notifications />

                  {this.getNotifications(this.state.newleads)}
                </HeaderNotifyCSS>
              </React.Fragment>
            )}

            <Menu
              user={this.state.name && this.state.name}
              role={
                this.props.user.findUserByToken &&
                this.props.user.findUserByToken.role
              }
              ref={this.Menu}
            />
          </HeaderCSS>
        </AlignHeader>
        <Router />
      </React.Fragment>
    );
  }

  render() {
    const { role, franchiseName } = this.state;

    return this.renderHeader(role, franchiseName);
  }
}

const queryFindUserByToken = gql`
  query queryFindLeadById {
    findUserByToken {
      id
      idFranchise
      name
      role
      franchise {
        id
        name
      }
    }
  }
`;

const queryFindAllOwnersByIdFranchise = gql`
  query queryFindAllOwnersByIdFranchise($idFranchise: ID) {
    findAllOwnersByIdFranchise(idFranchise: $idFranchise) {
      id
      idFranchise
      name
    }
  }
`;

const queryFindAllLeadsWithoutOwner = gql`
  query queryFindAllLeadsWithoutOwner($idFranchise: ID!) {
    findAllLeadsWithoutOwner(idFranchise: $idFranchise) {
      lead {
        id
        name
        idCourse
      }
      totalLeads
    }
  }
`;

const mutationCreateStageFranchise = gql`
  mutation createAllStageFranchisesByIdFranchise($idFranchise: ID!) {
    createAllStageFranchisesByIdFranchise(idFranchise: $idFranchise)
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindUserByToken, {
    name: "user",
    options: {
      fetchPolicy: "cache-and-network"
    }
  }),
  graphql(queryFindAllOwnersByIdFranchise, {
    name: "colaborators",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),
  graphql(queryFindAllLeadsWithoutOwner, {
    name: "withoutOwner",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0
      },
      fetchPolicy: "cache-and-network"
      // pollInterval: 82000
    })
  }),
  graphql(mutationCreateStageFranchise, {
    name: "createStageFranchise"
  })
)(Drawer);
