import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonClick = styled.button`
  color: #fff;
  background-color: ${props => props.background || "#FF2244"};
  outline: none;
  border: none;
  width: ${props => props.width || "auto"};
  margin: ${props => props.margin || "1rem 0"};
  margin-right: ${props => props.marginRight || "1rem"}
  height: ${props => props.height || "45px"};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: ${props => props.borderRadios || "12px"};
  cursor: pointer;
  padding: 0 1rem;
  display: table;
  visibility: ${props => props.hidden || "initial"};
`;

const ButtonLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Button extends React.Component {
  render() {
    const {
      color,
      width,
      onClick,
      hidden,
      margin,
      borderRadios,
      height,
      marginRight
    } = this.props;

    return (
      <ButtonClick
        margin={margin}
        hidden={hidden}
        height={height}
        type="button"
        onClick={onClick}
        width={width}
        background={color}
        borderRadios={borderRadios}
        marginRight={marginRight}
      >
        <ButtonLabel>{this.props.children}</ButtonLabel>
      </ButtonClick>
    );
  }
}

Button.propTypes = {
  hidden: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
