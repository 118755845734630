import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import MetaInfo from "../../components/MetaInfo";
import BulletInfo from "../../components/BulletInfo";
import LabelSelect from "../../components/LabelSelect";
import WifiTethering from "@material-ui/icons/WifiTethering";
import Event from "@material-ui/icons/Event";
import Phone from "@material-ui/icons/Phone";
import Room from "@material-ui/icons/Room";
import QueryBuilder from "@material-ui/icons/QueryBuilder";
import Button from "../../components/Button";
import LinkForm from "../../components/LinkForm";
import Tag from "../../components/Tag";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import Loading from "./../../components/Loading";
import { Link, navigate } from "@reach/router";
import media from "styled-media-query";
import Swal from "sweetalert2";

// import Modal from "./../../components/Modal";
// import FormTitle from "../../components/FormTitle";

const ScheduleScreenCSS = styled.div`
  background: #2a2e43;
  width: 100%;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

const ScheduleScreenHeaderCSS = styled.div`
  padding: 1.5rem;
  background: #fff;
`;

const ScheduleScreenBodyCSS = styled.div`
  padding: 1.5rem;
  background: #2a2e43;
  border-top: solid 7px #181d3a;
`;

// const ScheduleScreentTextCSS = styled.div`
//   font-size: 0.8rem;
//   text-align: center;
//   line-height: 1.5;
//   margin: 1rem 0;
// `;

class ScheduleScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: "",
      leadDetail: {},
      type: 0,
      date: "",
      horary: "",
      idLead: this.props.location.state.idLead,
      idSchedule:
        (props.leadStatus.findLastScheduleIncompleteByIdLead &&
          props.leadStatus.findLastScheduleIncompleteByIdLead.id) ||
        0,
      modal: false
    };

    this.handleScheduling = this.handleScheduling.bind(this);
    this.handleConclude = this.handleConclude.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  componentWillMount() {
    if (!this.props.location.state.idLead) {
      navigate("/home");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.leadDetails.loading !== nextProps.leadDetails.loading &&
      nextProps.leadDetails
    ) {
      const leadDetail = { ...nextProps.leadDetails.findLeadById };
      this.setState({
        leadDetail,
        type: leadDetail.schedule && leadDetail.schedule.type,
        date: leadDetail.schedule
          ? moment(leadDetail.schedule.date).format("YYYY-MM-DD")
          : "",
        horary: leadDetail.schedule ? leadDetail.schedule.startTime : ""
      });
    }

    if (
      this.props.leadStatus.findLastScheduleIncompleteByIdLead !==
      nextProps.leadStatus.findLastScheduleIncompleteByIdLead
    ) {
      if (nextProps.leadStatus.findLastScheduleIncompleteByIdLead) {
        return this.setState({
          idSchedule: parseInt(
            nextProps.leadStatus.findLastScheduleIncompleteByIdLead.id
          )
        });
      } else {
        return this.setState({
          idSchedule: null
        });
      }
    }
  }

  errorParams() {
    let noParamns = [];
    let message = "Por favor, insira o(s) dado(s):";

    if (!this.state.type) {
      noParamns.push(" ação");
    }

    if (!this.state.date) {
      noParamns.push(" data");
    }
    if (!this.state.horary) {
      noParamns.push(" horário");
    }

    if (noParamns.length) {
      return message + noParamns + ".";
    }
    return "";
  }

  async handleConclude() {
    const conclude = {
      id: this.state.idSchedule,
      status: "Complete"
    };

    await this.props.updateStatusSchedule({
      variables: {
        ...conclude
      }
    });

    navigate("/calendar");
  }

  async handleCancel() {
    const { idSchedule } = this.state;
    await this.props.destroySchedule({
      variables: {
        id: idSchedule
      }
    });

    navigate("/leads");
  }

  async handleScheduling() {
    if (this.errorParams()) {
      return Swal.fire({
        title: "Ops!",
        text: this.errorParams(),
        type: "error",
        confirmButtonText: "Ok"
      });
    }

    const schedule = {
      idLead: this.state.idLead,
      type: this.state.type,
      status: "Incomplete",
      date: this.state.date,
      startTime: this.state.horary
    };

    await this.props.createSchedule({
      variables: {
        ...schedule
      }
    });

    this.setState({ type: 0, status: "", date: "", startTime: "" });

    navigate("/calendar");
  }

  getTag(incomplete) {
    return incomplete && <Tag text="Não Concluído" color="#e67e22" />;
  }

  getButton(incomplete, handleConclude, handleScheduling) {
    if (incomplete) {
      return (
        <div>
          <Button onClick={handleConclude} width="100%" color="#e67e22">
            Concluir atendimento
          </Button>
          <LinkForm
            disabledLink
            onClick={() => this.handleCancel()}
            color="#fff"
            text="Cancelar Agendamento"
          />
        </div>
      );
    } else {
      return (
        <Button onClick={handleScheduling} width="100%" color="#FF2244">
          Agendar
        </Button>
      );
    }
  }

  // handleModal() {
  //   if (this.state.modal === true) {
  //     this.setState({ modal: false });
  //   } else {
  //     this.setState({ modal: true });
  //   }
  // }

  // renderModal() {
  //   if (this.state.modal === true) {
  //     return (
  //       <Modal>
  //         <FormTitle text="Deseja cancelar o agendamento?" />
  //         <ScheduleScreentTextCSS>
  //           Após efetuar o cancelamento, ele não poderá mais ser recuperado.
  //         </ScheduleScreentTextCSS>
  //         <Button
  //           onClick={() => this.handleCancel()}
  //           width="100%"
  //           color="#FF2244"
  //         >
  //           Cancelar Agendamento
  //         </Button>
  //         <LinkForm
  //           onClick={() => this.handleModal()}
  //           margin="1.5rem 0 0 0"
  //           text="Voltar"
  //         />
  //       </Modal>
  //     );
  //   }
  // }

  render() {
    const isIncomplete = !!this.state.idSchedule;

    return (
      <React.Fragment>
        {!this.props.leadDetails.loading || !this.props.leadStatus.loading ? (
          <ScheduleScreenCSS>
            <ScheduleScreenHeaderCSS>
              <Title
                align="center"
                size="2rem"
                color="black"
                text="Agendamento"
              />
              {this.getTag(isIncomplete)}
            </ScheduleScreenHeaderCSS>
            <ScheduleScreenBodyCSS>
              <Link to={`/profile/${this.props.location.state.idLead}`}>
                <Title
                  margin="0 0 .5rem 0"
                  align="left"
                  size="1.2rem"
                  color="white"
                  text={this.state.leadDetail.name || ""}
                />
              </Link>
              <MetaInfo
                color="white"
                label="Mídia"
                value={
                  (this.state.leadDetail &&
                    this.state.leadDetail.media &&
                    this.state.leadDetail.media.name) ||
                  "desconhecido"
                }
              />

              <BulletInfo
                color="white"
                value={
                  (this.state.leadDetail.course &&
                    this.state.leadDetail.course.name) ||
                  "desconhecido"
                }
              />

              <LabelSelect
                type="select"
                icon={<WifiTethering fontSize="small" />}
                text="Ação:"
                value={this.state.type}
                ref={this.mySelect}
                // defaultValue={this.state.type}
                disabled={isIncomplete}
                onChange={e =>
                  this.setState({ type: parseInt(e.target.value) })
                }
              >
                <option>Selecione</option>
                <option selected={this.state.type === 1} value={1}>
                  Atendimento online
                </option>
                <option selected={this.state.type === 2} value={2}>
                  Ligação
                </option>
                <option selected={this.state.type === 4} value={4}>
                  Tele-venda
                </option>
                <option selected={this.state.type === 5} value={5}>
                  PAP
                </option>
              </LabelSelect>

              <LabelSelect
                type="date"
                icon={<Event fontSize="small" />}
                text="Data:"
                disabled={isIncomplete}
                value={this.state.date}
                onChange={e => this.setState({ date: e.target.value })}
              />
              <LabelSelect
                type="time"
                disabled={isIncomplete}
                icon={<QueryBuilder fontSize="small" />}
                text="Hora:"
                value={this.state.horary}
                onChange={e => this.setState({ horary: e.target.value })}
              />
              <LabelSelect
                type="disabled"
                icon={<Phone fontSize="small" />}
                text="Tel:"
                value={this.state.leadDetail.cellphone || ""}
              />
              <LabelSelect
                type="disabled"
                icon={<Room fontSize="small" />}
                text="End.:"
                value={this.state.leadDetail.address || ""}
              />

              {this.getButton(
                isIncomplete,
                this.handleConclude,
                this.handleScheduling,
                this.handleCancel
              )}
            </ScheduleScreenBodyCSS>

            {/* {this.renderModal()} */}
          </ScheduleScreenCSS>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

const queryFindLeadById = gql`
  query queryFindLeadById($id: ID!) {
    findLeadById(id: $id) {
      id
      name
      idMedia
      media {
        id
        name
      }
      course {
        id
        name
      }
      address
      cellphone
      schedule {
        id
        date
        startTime
        type
      }
    }
  }
`;

const queryFindLastScheduleIncompleteByIdLead = gql`
  query queryFindLastScheduleIncompleteByIdLead($idLead: ID!) {
    findLastScheduleIncompleteByIdLead(idLead: $idLead) {
      id
    }
  }
`;

const createSchedule = gql`
  mutation createSchedule(
    $type: Int!
    $idLead: ID!
    $status: String!
    $date: String!
    $startTime: String!
  ) {
    createSchedule(
      idLead: $idLead
      type: $type
      status: $status
      date: $date
      startTime: $startTime
    )
  }
`;
const updateStatusSchedule = gql`
  mutation updateStatusSchedule($status: String!, $id: ID!) {
    updateStatusSchedule(status: $status, id: $id)
  }
`;
const destroySchedule = gql`
  mutation destroySchedule($id: ID!) {
    destroySchedule(id: $id)
  }
`;

export default compose(
  graphql(queryFindLeadById, {
    name: "leadDetails",
    options: props => ({
      variables: {
        id: props.location.state.idLead || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),
  graphql(queryFindLastScheduleIncompleteByIdLead, {
    name: "leadStatus",
    options: props => ({
      variables: {
        idLead: props.location.state.idLead || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),
  graphql(createSchedule, { name: "createSchedule" }),
  graphql(updateStatusSchedule, { name: "updateStatusSchedule" }),
  graphql(destroySchedule, { name: "destroySchedule" })
)(ScheduleScreen);
