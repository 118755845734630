import React, { Component } from "react";
import FileUploadIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core";
import FileDownloadIcon from "@material-ui/icons/GetApp";

const styles = {
  inputALign: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundColor: "#ff2244",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    fontFamily: "Montserrat",
    cursor: "pointer"
  },
  modelExcelTextAlign: {
    display: "flex",
    justifyContent: "space-around",
    textDecoration: "underline",
    alignItems: "center",
    marginBottom: "32px"
  },
  input: {
    display: "none"
  },
  Icon: {
    paddingBottom: "8px"
  }
};

class Input extends Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }
  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <div >
        <a
          href="https://www.institutomix.com.br/crm/modelExcel/model.xls"
          download
        >
          <div className={classes.modelExcelTextAlign}>
            modelo.xls
            <FileDownloadIcon />
          </div>
        </a>
        <div
          style={{
            opacity: this.props.disabled ? 0.5 : "",
            cursor: this.props.disabled ? "default" : "pointer"
          }}
          className={classes.inputALign}
          onClick={this.openFileDialog}
        >
          <input
            className={classes.input}
            ref={this.fileInputRef}
            onDragOver={this.onDragOver}
            onChange={this.onFilesAdded}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            type="file"
          />
          <FileUploadIcon className={classes.Icon} fontSize="large" />

          <span>Selecionar arquivo</span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Input);
