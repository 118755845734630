import React from "react";
import styled from "styled-components";

const FilterItemCSS = styled.div`
  background: #4d97fd;
  display: table;
  color: #fff;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border-radius: 26px;
  margin: 0.5rem 0.5rem 0 0;
  position: relative;
  overflow: hidden;
`;

const FilterItemRemoveCSS = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #3a72bf;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  cursor: pointer;
`;

export const FilterItem = props => {
  return (
    <React.Fragment>
      {props.name && (
        <FilterItemCSS>
          {props.name}

          <FilterItemRemoveCSS onClick={props.onClick}>x</FilterItemRemoveCSS>
        </FilterItemCSS>
      )}
    </React.Fragment>
  );
};

export default FilterItem;
