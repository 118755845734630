import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";

const LinkFormCss = styled.p`
  text-align: center;
  display: block;
  margin: ${props => props.margin || "1.5rem 0"};
  font-size: 0.8rem;
  color: ${props => props.color || "#000000ba"};
`;

class LinkForm extends React.Component {
  render() {
    const { link, text, margin, onClick, color, disabledLink } = this.props;
    return (
      <Link
        to={disabledLink ? "#" : link}
        onClick={e => disabledLink && e.preventDefault()}
      >
        <LinkFormCss onClick={onClick} margin={margin} color={color}>
          {text}
        </LinkFormCss>
      </Link>
    );
  }
}

export default LinkForm;
