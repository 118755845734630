import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StarIcon from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";

const RateCSS = styled.div`
  color: #ffb900;
  margin: 0.7rem 0;
  position: relative;
  left: -4px;
`;

class Rate extends React.Component {
  drawStar(value) {
    let stars = [];

    for (let i = 0; i < 5; i++) {
      if (i < value) {
        stars.push(<StarIcon key={i} fontSize="small" />);
      } else {
        stars.push(<StarBorder key={i} fontSize="small" />);
      }
    }

    return stars;
  }

  render() {
    const { value } = this.props;

    return <RateCSS>{this.drawStar(value)}</RateCSS>;
  }
}

Rate.propTypes = {
  value: PropTypes.number
};

export default Rate;
