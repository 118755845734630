import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 32
  }
};

class Loading extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary"  />
      </div>
    );
  }
}

export default withStyles(styles)(Loading);
