import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BulletInfoCSS = styled.div`
    font-size: .8rem;
    line-height: 1.5;
    margin: .5rem 0;
    color: ${props => props.color || "#000000"};
`;

const BulletCSS = styled.i`
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #FF2244;
    border-radius: 100%;
    position: relative;
    top: 0px;
    
`;

class BulletInfo extends React.Component {

    render() {
        const {  value, color } = this.props
        return (
            <BulletInfoCSS color={color}>
                <BulletCSS /> {value}
            </BulletInfoCSS>
        );
    }
}

BulletInfo.propTypes = {
  value: PropTypes.string
};

export default BulletInfo;
