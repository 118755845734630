import React from "react";
import CPF from "cpf";
import styled from "styled-components";
import Title from "../../components/Title";
import FormGroup from "../../components/FormGroup";
import FormTitle from "../../components/FormTitle";
import FormFields from "../../components/FormFields";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import SelectField from "../../components/SelectField";
import LinkForm from "../../components/LinkForm";
import { graphql, compose } from "react-apollo";
import { withUIContext } from "./../../contexts/UIContext";
import AreaField from "../../components/AreaField";
import Loading from "./../../components/Loading";
import media from "styled-media-query";
import Swal from "sweetalert2";
import moment from "moment";
import { navigate } from "@reach/router";
import gql from "graphql-tag";

const NewCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

class NewLead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role: this.props.uiContext.role || "",
      inputDate: "text",
      colaborators: [],
      idOwner: 0,
      name: "",
      whatsapp: "",
      email: "",
      idCourse: 0,
      birth: moment().subtract(12, "years").format("YYYY-MM-DD"),
      state: "",
      city: "",
      neighborhood: "",
      address: "",
      number: "",
      rg: "",
      cpf: "",
      note: "",
      rate: 0,
      courses: props.allCourses.findAllGenericCourses || [],
      medias: props.allMedias.findAllMedias || [],
      franchiseCampaigns:
        props.allFranchiseCampaigns.findAllFranchiseCampaignsByIdFranchise ||
        [],
      idMedia: 0,
      idCampaign: 0,
      isLoading: false,
    };

    this.inputFocus = this.utilizeFocus();
    this.handleSave = this.handleSave.bind(this);
    this.setTypeDate = this.setTypeDate.bind(this);
  }

  utilizeFocus = () => {
    const ref = React.createRef();
    const setFocus = () => {
      ref.current && ref.current.focus();
    };

    return { setFocus, ref };
  };

  setTypeDate() {
    this.setState({ inputDate: "date" });
  }

  setTypeText() {
    this.setState({
      inputDate: "text",
    });
  }

  handleNewLeadOffice(idOwner, role) {
    if (
      role === "owner" ||
      role === "telemarketing operator" ||
      role === "media assistant"
    ) {
      return this.props.uiContext.id;
    } else {
      if (idOwner) {
        return idOwner;
      }
    }
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.uiContext !== this.props.uiContext && this.props.uiContext) {
      const { role } = this.props.uiContext;

      this.setState({ role });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.allFranchiseCampaigns.loading &&
      !this.props.allFranchiseCampaigns.loading &&
      this.props.allFranchiseCampaigns
    ) {
      this.setState({
        franchiseCampaigns: this.props.allFranchiseCampaigns
          .findAllFranchiseCampaignsByIdFranchise,
      });
    }
    if (
      prevProps.allMedias.loading &&
      !this.props.allMedias.loading &&
      this.props.allMedias
    ) {
      this.setState({ medias: this.props.allMedias.findAllMedias });
    }
    if (
      prevProps.allCourses.loading &&
      !this.props.allCourses.loading &&
      this.props.allCourses
    ) {
      this.setState({ courses: this.props.allCourses.findAllGenericCourses });
    }

    if (prevProps.colaborators.loading && !this.props.colaborators.loading) {
      const colaborators = this.props.colaborators.findAllOwnersByIdFranchise;

      this.setState({ colaborators });
    }
    if (
      prevProps.leadById.loading &&
      !this.props.leadById.loading &&
      this.props.leadById.findLeadById
    ) {
      const lead = this.props.leadById.findLeadById;

      this.setState({
        cpf: lead.cpf || "",
        rg: lead.rg || "",
        name: lead.name || "",
        address: lead.address || "",
        number: lead.number || "",
        birth: lead.birth || "",
        neighborhood: lead.neighborhood || "",
        whatsapp: lead.cellphone || "",
        idMedia: lead.idMedia || 0,
        idCourse: parseInt(lead.idCourse) || 0,
        idOwner: lead.idOwner || 0,
        rate: lead.rate || 0,
        note: lead.note || "",
        city: lead.city || "",
        state: lead.state || "",
        email: lead.email || "",
        idCampaign: parseInt(lead.idCampaign) || 0,
      });
    }
  }

  async createLead() {
    const parameters = {
      idFranchise: this.props.uiContext.idFranchise,
      cpf: this.state.cpf,
      state: this.state.state,
      rg: this.state.rg,
      name: this.state.name,
      address: this.state.address,
      number: this.state.number || "",
      birth: this.state.birth,
      neighborhood: this.state.neighborhood,
      cellphone: this.state.whatsapp.replace(/[^0-9]+/g, ""),
      idMedia: parseInt(this.state.idMedia),
      idCourse: parseInt(this.state.idCourse),
      idOwner: this.handleNewLeadOffice(
        this.state.idOwner,
        this.props.uiContext.role
      ),
      idManager: parseInt(this.props.uiContext.id),
      idCampaign: this.state.idCampaign,
      note: this.state.note,
      rate: parseInt(this.state.rate),
      city: this.state.city,
      email: this.state.email,
    };

    return await this.props.createLead({
      variables: {
        ...parameters,
      },
    });
  }

  async editLead() {
    const {
      cpf,
      rg,
      address,
      number,
      birth,
      name,
      neighborhood,
      whatsapp,
      note,
      idMedia,
      idCourse,
      rate,
      city,
      state,
      email,
      idCampaign,
    } = this.state;

    const idLead = this.props.id;

    const params = {
      id: idLead,
      cpf,
      rg,
      address,
      number,
      birth,
      name,
      neighborhood,
      cellphone: whatsapp,
      note,
      idMedia: parseInt(idMedia),
      idCourse: parseInt(idCourse),
      idOwner: this.handleNewLeadOffice(
        this.state.idOwner,
        this.props.uiContext.role
      ),
      city,
      state,
      rate: parseInt(rate),
      email,
      idCampaign,
    };

    return await this.props.updateLead({ variables: { ...params } });
  }

  async handleSave() {
    this.setState({ isLoading: true });
    const idLead = !!this.props.id;
    let counter = 0;
    let error = [];

    if (this.state.cpf && !CPF.isValid(this.state.cpf)) {
      counter += 1;
      error.push(`<br>${counter} -  CPF inválido`);
    }

    if (
      !this.state.idOwner &&
      (this.state.role === "franchiseOwner" || this.state.role === "manager")
    ) {
      counter += 1;
      error.push(`<br>${counter} - Orientador`);
    }

    if (!this.state.name) {
      counter += 1;
      error.push(`<br>${counter} - Nome`);
    }

    if (!this.state.whatsapp) {
      counter += 1;
      error.push(`<br>${counter} - Whatsapp`);
    }

    if (error.length) {
      this.setState({ isLoading: false });
      return Swal.fire({
        title: "Ops!",
        html: "Os seguintes campos são obrigatórios: " + error + ".",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    if (idLead) {
      await this.editLead();
    } else {
      await this.createLead();
    }

    this.setState({ isLoading: false });

    navigate("/leads");
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const isEditable = !!this.props.id;
    let {
      role,
      courses,
      medias,
      franchiseCampaigns,
      idCampaign,
      idMedia,
      idCourse,
      name,
      whatsapp,
      email,
      birth,
      state,
      city,
      neighborhood,
      address,
      number,
      rg,
      cpf,
      rate,
      idOwner,
      colaborators,
      note,
      isLoading,
    } = this.state;

    return (
      <React.Fragment>
        {!this.props.leadById.loading || !this.props.colaborators.loading ? (
          <NewCSS>
            {isEditable ? (
              <Title
                align="center"
                size="2rem"
                color="black"
                text="Editar Lead"
              />
            ) : (
              <Title
                align="center"
                size="2rem"
                color="black"
                text="Novo Lead"
              />
            )}

            <form>
              <FormGroup>
                <FormTitle />
                <FormFields>
                  <TextField
                    value={name}
                    onChange={this.handleChange("name")}
                    label="Nome"
                  />
                  <TextField
                    value={whatsapp}
                    onChange={this.handleChange("whatsapp")}
                    label="Whatsapp"
                    type="tel"
                    mask="(99) 9 9999-9999"
                  />
                  <TextField
                    value={email}
                    onChange={this.handleChange("email")}
                    label="E-mail"
                    type="email"
                  />
                  <SelectField
                    value={idCampaign}
                    onChange={this.handleChange("idCampaign")}
                  >
                    <option value="">Campanha </option>

                    {franchiseCampaigns.map((campaign, index) => (
                      <option
                        key={index}
                        selected={idCampaign === campaign.id}
                        value={campaign.id}
                      >
                        {campaign.name}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    value={idMedia}
                    onChange={this.handleChange("idMedia")}
                  >
                    <option value="">Mídia </option>

                    {medias.map((media, index) => (
                      <option
                        key={index}
                        selected={parseInt(idMedia) === media.id}
                        value={media.id}
                      >
                        {media.name}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    value={idCourse}
                    onChange={this.handleChange("idCourse")}
                  >
                    <option value="">Curso Desejado </option>

                    {courses.map((course, index) => (
                      <option
                        key={index}
                        selected={idCourse === course.id}
                        value={course.id}
                      >
                        {course.name}
                      </option>
                    ))}
                  </SelectField>
                  <TextField
                    value={birth}
                    onChange={this.handleChange("birth")}
                    label="Data de Nascimento"
                    type="date"
                    ref={this.inputFocus.ref}
                  />
                  <TextField
                    value={state}
                    onChange={this.handleChange("state")}
                    label="Estado"
                  />
                  <TextField
                    value={city}
                    onChange={this.handleChange("city")}
                    label="Cidade"
                  />
                  <TextField
                    value={neighborhood}
                    onChange={this.handleChange("neighborhood")}
                    label="Bairro"
                  />
                  <TextField
                    value={address}
                    onChange={this.handleChange("address")}
                    label="Endereço"
                  />
                  <TextField
                    value={number}
                    onChange={this.handleChange("number")}
                    label="Número"
                  />
                  <TextField
                    value={rg}
                    onChange={this.handleChange("rg")}
                    label="RG"
                  />
                  <TextField
                    value={cpf}
                    onChange={this.handleChange("cpf")}
                    label="CPF"
                  />
                  <SelectField
                    value={rate}
                    onChange={(e) => this.setState({ rate: e.target.value })}
                  >
                    <option>Pontuação do lead</option>
                    <option selected={rate === 1} value={1}>
                      1 - Não tem interesse (topo de funil)
                    </option>
                    <option selected={rate === 2} value={2}>
                      2 - Tem interesse, mas não para agora (meio de funil)
                    </option>
                    <option selected={rate === 3} value={3}>
                      3 - Tem interesse, mas sem condições financeiras, horário
                      ou curso (fundo de funil)
                    </option>
                    <option selected={rate === 4} value={4}>
                      4 - Tem interesse, aguardando receber para pagar taxa em x
                      dias (fundo de funil)
                    </option>
                    <option selected={rate === 5} value={5}>
                      5 - Muito interessado, vindo fechar (fundo de funil)
                    </option>
                  </SelectField>
                  {role && (role === "manager" || role === "franchiseOwner") && (
                    <SelectField
                      value={idOwner}
                      onChange={(e) =>
                        this.setState({ idOwner: e.target.value })
                      }
                    >
                      <option value={null}>Orientador</option>
                      {colaborators.map((colaborator, index) => (
                        <option
                          selected={idOwner === colaborator.id}
                          key={index}
                          value={colaborator.id}
                        >
                          {colaborator.name}
                        </option>
                      ))}
                    </SelectField>
                  )}
                  <AreaField
                    value={note}
                    onChange={(e) => this.setState({ note: e.target.value })}
                    label="Observações:"
                  />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <Button
                        onClick={this.handleSave}
                        width="100%"
                        color="#FF2244"
                      >
                        {isEditable ? "Salvar" : "Cadastrar"}
                      </Button>
                      <LinkForm
                        link="/home"
                        margin="1.5rem 0 0 0"
                        text="Cancelar"
                      />
                    </React.Fragment>
                  )}
                </FormFields>
              </FormGroup>
            </form>
          </NewCSS>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

const queryFindAllOwnersByIdFranchise = gql`
  query queryFindAllOwnersByIdFranchise($idFranchise: ID) {
    findAllOwnersByIdFranchise(idFranchise: $idFranchise) {
      id
      idFranchise
      name
    }
  }
`;

const queryFindAllGenericCourses = gql`
  query queryFindAllGenericCourses {
    findAllGenericCourses {
      id
      name
    }
  }
`;

const queryFindAllMedias = gql`
  query queryFindAllMedias {
    findAllMedias {
      id
      name
    }
  }
`;

const queryFindAllFranchiseCampaignsByIdFranchise = gql`
  query queryFindAllFranchiseCampaignsByIdFranchise($idFranchise: ID) {
    findAllFranchiseCampaignsByIdFranchise(idFranchise: $idFranchise) {
      id
      name
    }
  }
`;

const queryFindLeadById = gql`
  query queryFindLeadById($id: ID) {
    findLeadById(id: $id) {
      id
      idOwner
      idManager
      idStageFranchise
      idFranchise
      name
      cpf
      rg
      idMedia
      idCourse
      address
      idCampaign
      city
      number
      birth
      state
      neighborhood
      cellphone
      rate
      note
      email
      stageFranchise {
        id
        stage {
          id
          name
        }
      }
    }
  }
`;

const createLead = gql`
  mutation createLead(
    $cpf: String
    $rg: String
    $address: String
    $name: String
    $number: String
    $birth: String
    $neighborhood: String
    $cellphone: String!
    $note: String
    $idMedia: ID
    $idCourse: ID
    $idFranchise: ID!
    $idOwner: ID
    $idManager: ID
    $rate: Int
    $city: String
    $state: String
    $email: String
    $idCampaign: ID
  ) {
    createLead(
      cpf: $cpf
      rg: $rg
      address: $address
      number: $number
      birth: $birth
      name: $name
      neighborhood: $neighborhood
      cellphone: $cellphone
      note: $note
      idMedia: $idMedia
      idCourse: $idCourse
      idFranchise: $idFranchise
      idOwner: $idOwner
      idManager: $idManager
      rate: $rate
      city: $city
      state: $state
      email: $email
      idCampaign: $idCampaign
    )
  }
`;

const updateLead = gql`
  mutation updateLead(
    $id: ID!
    $idOwner: ID
    $cpf: String
    $rg: String
    $address: String
    $name: String
    $number: String
    $birth: String
    $neighborhood: String
    $cellphone: String
    $note: String
    $idMedia: ID
    $idCourse: ID
    $rate: Int
    $city: String
    $state: String
    $email: String
    $idCampaign: ID
  ) {
    updateLead(
      id: $id
      idOwner: $idOwner
      cpf: $cpf
      rg: $rg
      address: $address
      number: $number
      birth: $birth
      name: $name
      neighborhood: $neighborhood
      cellphone: $cellphone
      note: $note
      idMedia: $idMedia
      idCourse: $idCourse
      rate: $rate
      city: $city
      state: $state
      email: $email
      idCampaign: $idCampaign
    )
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllOwnersByIdFranchise, {
    name: "colaborators",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindLeadById, {
    name: "leadById",
    options: (props) => ({
      variables: {
        id: props.id || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllFranchiseCampaignsByIdFranchise, {
    name: "allFranchiseCampaigns",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),

  graphql(queryFindAllGenericCourses, {
    name: "allCourses",
  }),
  graphql(queryFindAllMedias, {
    name: "allMedias",
  }),
  graphql(createLead, { name: "createLead" }),
  graphql(updateLead, { name: "updateLead" })
)(NewLead);
