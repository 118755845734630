import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const FormTitleCSS = styled.div`
  display: block;
  border-bottom: solid 4px #d01b36;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
`;

class FormTitle extends React.Component {
  render() {
    const { text } = this.props;

    return <FormTitleCSS>{text}</FormTitleCSS>;
  }
}

FormTitle.propTypes = {
  text: PropTypes.string
};

export default FormTitle;
