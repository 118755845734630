import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./../../components/Title";

const useStyles = makeStyles(theme => ({
  root: {
    width: "28%",
    maxHeight: 400,
    margin: "16px",
    [theme.breakpoints.down(785)]: {
      width: "85%",
      paddingBottom: 80,
      marginBottom: 100
    }
  },
  table: {
    minWidth: 50,
    height: 200
  },
  Title: {
    display: "flex",
    justifyContent: "center"
  },
  scroolTable: {
    overflowX: "auto",
    maxHeight: 400
  }
}));

export const TableModel = props => {
  const { rows, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.Title}>
        <Title size="1rem" text={title} margin="2rem" />
      </div>
      <div className={classes.scroolTable}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell align="center">Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default TableModel;
