import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const ModalHeaderCSS = styled.div`
  top: 5.2%;
  left: 0%;
  position: absolute;
  width: 100%;
  height: 60px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  ${media.greaterThan("740px")`
    width: 100%;
    top: 4%;
    height: 100px;
    justify-content: space-around;
`}
`;

const AlignHeaderCSS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SizeCSS = styled.p`
  margin-right: 16px;
`;

export const ModalHeader = props => {
  return (
    <ModalHeaderCSS>
      <AlignHeaderCSS>

        {props.icon}
        <SizeCSS> {props.size} </SizeCSS>

      </AlignHeaderCSS>
      {props.children}
    </ModalHeaderCSS>
  );
};

export default ModalHeader;
