import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styled-media-query";

const AvatarCSS = styled.div`
  width: ${props => props.width || "45px"};
  height: ${props => props.height || "45px"};
  background: #ff2244;
  border-radius: ${props => props.radius || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  float: ${props => props.float || "left"};
  margin: ${props => props.margin || "0 1rem 50px 0"};
  ${media.greaterThan("740px")`
    float:none;
    margin: 0 auto .7rem auto;
  `}
`;

class Avatar extends React.Component {
  generateAvatar(name) {

    if (name) {
      let name_arr = name.split("");

      return name_arr[0];
    }
  }

  render() {
    const { name, width, height, radius, float, margin, style } = this.props;

    return (
      <AvatarCSS
        style={style}
        margin={margin}
        float={float}
        width={width}
        height={height}
        radius={radius}
      >
        {this.generateAvatar(name)}
      </AvatarCSS>
    );
  }
}

Avatar.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  radius: PropTypes.string
};

export default Avatar;
