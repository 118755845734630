import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InputMask from "react-input-mask";

const TextFieldCss = styled.input`
  border: none;
  min-width: 95%;
  outline: none;
  height: 45px;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  border: solid 1px #cecece;
  margin: 1.2rem 0;
  font-size: 0.8rem;
  position: ${props => props.position || "inherit"};
  top: ${props => props.top || "inherit"};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 40px;
`;
// background: #ffffff;

const TextFieldMaskCss = styled(InputMask)`
  outline: none;
  height: 45px;
  width: 100%;
  display: block;
  border-radius: 10px;
  padding: 1rem;
  border: solid 1px #cecece;
  margin: 1.2rem 0;
  font-size: 0.8rem;
  position: ${props => props.position || "inherit"};
  top: ${props => props.top || "inherit"};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 40px;
  background: #ffffff;
`;

class TextField extends React.Component {
  renderTextField(
    label,
    type,
    position,
    top,
    icon,
    onFocus,
    onBlur,
    mask,
    onChange,
    value,
    onKeyUp,
    onClick,
    defaultValue
  ) {
    if (mask) {
      return (
        <TextFieldMaskCss
          mask={mask}
          icon={icon}
          position={position}
          top={top}
          placeholder={label}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          onChange={onChange}
          value={value}
          onKeyUp={onKeyUp}
          onClick={onClick}
          defaultValue={defaultValue}
        />
      );
    } else {
      return (
        <TextFieldCss
          onChange={onChange}
          value={value}
          icon={icon}
          position={position}
          top={top}
          placeholder={label}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          onKeyUp={onKeyUp}
          onClick={onClick}
          defaultValue={defaultValue}
        />
      );
    }
  }

  render() {
    const {
      label,
      type,
      position,
      top,
      icon,
      onFocus,
      onBlur,
      mask,
      onChange,
      value,
      onKeyUp,
      onClick,
      defaultValue
    } = this.props;
    return (
      <div>
        {this.renderTextField(
          label,
          type,
          position,
          top,
          icon,
          onFocus,
          onBlur,
          mask,
          onChange,
          value,
          onKeyUp,
          onClick,
          defaultValue
        )}
      </div>
    );
  }
}
TextField.propTypes = {
  position: PropTypes.string,
  top: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func
};

export default TextField;
