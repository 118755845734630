import React, { Component } from "react";
import styled from "styled-components";
import Input from "./../../components/Input";
import Button from "./../../components/Button";
import Progress from "./../../components/Progress";
import DoneIcon from "@material-ui/icons/Done";
import Swal from "sweetalert2";
import { withUIContext } from "./../../contexts/UIContext";
import Table from "./../../components/Table";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import Title from "./../../components/Title";
import Loading from "./../../components/Loading";
import media from "styled-media-query";

const AlignInputCSS = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const AlignFileNameCSS = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  font-family: Montserrat;
  margin-bottom: 16px;
`;

const AlignContentCSS = styled.div`
  display: flex;
  justify-content: center;
`;

const ProgressWidthCSS = styled.div`
  width: 50%;
`;

const AlignButtonCSS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const AlignProgressCSS = styled.div`
  display: flex;
  flexdirection: row;
`;

const AlignTables = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${media.lessThan("785px")`
  flex-direction: column;
`}
`;

const AlignTableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  margin-bottom: 80px;
  padding-top: 48px;
`;

class ImportLeadScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      message: "",
      courses: props.allCourses.findAllGenericCourses || [],
      medias: props.allMedias.findAllMedias || [],
      franchiseCampaigns:
        props.allFranchiseCampaigns.findAllFranchiseCampaignsByIdFranchise || []
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.allFranchiseCampaigns.loading &&
      !this.props.allFranchiseCampaigns.loading &&
      this.props.allFranchiseCampaigns
    ) {
      this.setState({
        franchiseCampaigns: this.props.allFranchiseCampaigns
          .findAllFranchiseCampaignsByIdFranchise
      });
    }
    if (
      prevProps.allMedias.loading &&
      !this.props.allMedias.loading &&
      this.props.allMedias
    ) {
      this.setState({ medias: this.props.allMedias.findAllMedias });
    }
    if (
      prevProps.allCourses.loading &&
      !this.props.allCourses.loading &&
      this.props.allCourses
    ) {
      this.setState({ courses: this.props.allCourses.findAllGenericCourses });
    }
  }

  onFilesAdded(files) {
    this.setState({
      files: files
    });
  }

  async sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.onreadystatechange = function() {
        const result = this.response && JSON.parse(this.response);

        if (this.readyState === 4 && this.status === 200) {
          return Swal.fire({
            position: "center",
            type: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 1700
          });
        } else if (this.status === 415) {
          return Swal.fire({
            title: "Ops!",
            text: result.message,
            type: "warning",
            confirmButtonText: "Ok"
          });
        } else if (this.status === 500) {
          return Swal.fire({
            title: "Ops!",
            text: result.message,
            type: "error",
            confirmButtonText: "Ok"
          });
        }
      };

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open(
        "POST",
        `${process.env.REACT_APP_UPLOAD}/${this.props.uiContext.idFranchise}`
      );

      req.send(formData);
    });
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });

    try {
      await this.sendRequest(this.state.files[0]);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <AlignProgressCSS>
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <DoneIcon color="secondary" />
        </AlignProgressCSS>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <Button
          width="120px"
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Limpar
        </Button>
      );
    } else {
      return (
        <Button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
          width="120px"
        >
          Importar leads
        </Button>
      );
    }
  }

  render() {
    const { franchiseCampaigns, medias, courses } = this.state;
    return (
      <React.Fragment>
        <AlignInputCSS>
          <Input
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
          />
        </AlignInputCSS>

        {this.state.files.map(file => {
          return (
            <div key={file.name}>
              <AlignFileNameCSS>
                <span>{file.name}</span>
              </AlignFileNameCSS>

              <AlignContentCSS>
                <ProgressWidthCSS>
                  {this.renderProgress(file)}

                  <AlignButtonCSS>{this.renderActions()}</AlignButtonCSS>
                </ProgressWidthCSS>
              </AlignContentCSS>
            </div>
          );
        })}

        {this.props.allFranchiseCampaigns.loading ||
        this.props.allCourses.loading ||
        this.props.allMedias.loading ? (
          <Loading />
        ) : (
          <AlignTableContent>
            <Title size="1rem" text="Códigos para preenchimento do Excel" />

            <AlignTables>
              <Table title="Cursos" rows={courses} />
              <Table title="Mídias" rows={medias} />
              <Table title="Campanhas" rows={franchiseCampaigns} />
            </AlignTables>
          </AlignTableContent>
        )}
      </React.Fragment>
    );
  }
}

const queryFindAllFranchiseCampaignsByIdFranchise = gql`
  query queryFindAllFranchiseCampaignsByIdFranchise($idFranchise: ID) {
    findAllFranchiseCampaignsByIdFranchise(idFranchise: $idFranchise) {
      id
      name
    }
  }
`;

const queryFindAllGenericCourses = gql`
  query queryFindAllGenericCourses {
    findAllGenericCourses {
      id
      name
    }
  }
`;

const queryFindAllMedias = gql`
  query queryFindAllMedias {
    findAllMedias {
      id
      name
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllFranchiseCampaignsByIdFranchise, {
    name: "allFranchiseCampaigns",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),

  graphql(queryFindAllGenericCourses, {
    name: "allCourses"
  }),
  graphql(queryFindAllMedias, {
    name: "allMedias"
  })
)(ImportLeadScreen);
