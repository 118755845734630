import React from "react";
import styled from "styled-components";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import media from "styled-media-query";
import moment from "moment";
import Title from "./../../components/Title";
import Button from "./../../components/Button";
import SearchImage from "../../assets/images/icons-search.png";
import TextField from "./../../components/TextField";
import SimpleCard from "./../../components/SimpleCard";
import Empty from "./../../components/Empty";
import Loading from "./../../components/Loading";
import FilterIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "material-ui-flat-pagination";
import BackIcon from "@material-ui/icons/ArrowBack";
import MoreVerIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ChangeIcon from "@material-ui/icons/SwapHoriz";
import Tooltip from "@material-ui/core/Tooltip";
import ModalFilter from "./../../components/ModalFilter";
import ModalHeader from "./../../components/ModalHeader";
import Swal from "sweetalert2";
import { navigate } from "@reach/router";
import FilterField from "./../../components/FilterField";
import DataList from "./../../components/DataList";
import DataListItem from "./../../components/DataListItem";
import FilterItem from "./../../components/FilterItem";
import LinkForm from "./../../components/LinkForm";
import Modal from "./../../components/Modal";
import FormGroup from "../../components/FormGroup";
import FormTitle from "../../components/FormTitle";
import FormFields from "../../components/FormFields";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const LeadsCSS = styled.div`
  padding: 2rem 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${media.greaterThan("740px")`
    max-width:820px;
  `}
`;

const ContentCSS = styled.div`
  width: 100%;
  max-width: 790px;
`;

const FieldsLeadsCSS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextFieldFullCSS = styled.div`
  width: 100%;
  margin: 0px;
`;

const CardLeads = {
  width: "100%",
  margin: "0 0 1rem 0",
};

const PaginationCSS = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const CheckBoxCSS = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  ${media.greaterThan("740px")`
    position: relative;
    right: initial;
    transform: initial;
  `}
`;

const CheckBoxOutlineBlankIconCSS = styled(CheckBoxOutlineBlankIcon)`
  font-size: 30px;
`;

const CheckBoxIconCSS = styled(CheckBoxIcon)`
  font-size: 30px;
`;

const AlignSettingsButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ChangeIconCSS = styled(ChangeIcon)`
  margin-right: 12px;
`;

const SelectOwnerCSS = styled.select`
  border: none;
  left: 10px;
  outline: none;
  background: #fbfbfb;
  alignself: center;
  maxwidth: 150px;
`;

const Text = styled.p`
  line-height: 1.5;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const ButtonExport = styled.div`
  color: #fff;
  background-color: #ff2244;
  border: none;
  width: 100%;
  margin: 1rem 0;
  height: 45px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 12px;
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: initial;
`;

class LeadsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      allLeadsIdSelected: [],
      offset: 0,
      totalLeads: 0,
      displayModalFilter: false,
      displayModalExport: false,
      isLeadConfiguration: false,
      checked: false,
      ownerOptionChoice: false,
      anchorEl: null,
      leads: [],
      courses: props.allCourses.findAllGenericCourses || [],
      medias: props.allMedias.findAllMedias || [],
      campaigns:
        props.allFranchiseCampaigns.findAllFranchiseCampaignsByIdFranchise ||
        [],
      stages: [],
      filteredCoursesList: [],
      filterCourse: "",
      filterCourseOk: { id: 0, name: "", filterComplete: false },
      filteredMediasList: [],
      filterMedia: "",
      filterMediaOk: { id: 0, name: "", filterComplete: false },
      filterCampaign: "",
      filteredCampaignsList: [],
      filterCampaignOk: { id: 0, name: "", filterComplete: false },
      filteredStagesList: [],
      filterStage: "",
      filterStageOk: { id: 0, name: "", filterComplete: false },
      isNewExcelData: false,
      leadsForExcel: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleDestroyLeads = this.handleDestroyLeads.bind(this);
    this.fileExportRef = React.createRef();
    this.filterExportLead = this.filterExportLead.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.leadsForExcel.loading &&
      !this.props.leadsForExcel.loading &&
      this.props.leadsForExcel &&
      this.state.isNewExcelData === true
    ) {
      const leadsForExcel =
        this.props.leadsForExcel &&
        this.props.leadsForExcel.findAllLeadsByFilterForExportExcel &&
        this.props.leadsForExcel.findAllLeadsByFilterForExportExcel.length &&
        this.props.leadsForExcel.findAllLeadsByFilterForExportExcel;

      this.setState({ isNewExcelData: false, leadsForExcel });
    }

    if (this.props.leads.variables.offset !== this.state.offset) {
      this.props.leads.refetch({ offset: this.state.offset });
    }

    if (
      prevProps.allStageFranchise.loading &&
      !this.props.allStageFranchise.loading &&
      this.props.allStageFranchise
    ) {
      const stageFranchise = this.props.allStageFranchise
        .findAllStageFranchiseByIdFranchise;

      const stages = stageFranchise.map((stageFranchise) => {
        return {
          ...stageFranchise.stage,
        };
      });

      this.setState({
        stages,
      });
    }

    if (
      prevProps.allFranchiseCampaigns.loading &&
      !this.props.allFranchiseCampaigns.loading &&
      this.props.allFranchiseCampaigns
    ) {
      this.setState({
        campaigns: this.props.allFranchiseCampaigns
          .findAllFranchiseCampaignsByIdFranchise,
      });
    }
    if (
      prevProps.allMedias.loading &&
      !this.props.allMedias.loading &&
      this.props.allMedias
    ) {
      this.setState({ medias: this.props.allMedias.findAllMedias });
    }
    if (
      prevProps.allCourses.loading &&
      !this.props.allCourses.loading &&
      this.props.allCourses
    ) {
      this.setState({ courses: this.props.allCourses.findAllGenericCourses });
    }

    if (this.props.uiContext.idOwner !== prevProps.uiContext.idOwner) {
      this.props.leads.refetch({
        name: this.state.name,
        idOwner: this.props.uiContext.idOwner || "",
        offset: 0,
      });
    }

    if (this.props.leads.loading !== prevProps.leads.loading) {
      let lastTags = [];

      this.props.leads.findLeadByFilter &&
        this.props.leads.findLeadByFilter.lead &&
        this.props.leads.findLeadByFilter.lead.map((lead) =>
          lastTags.push(
            (lead.leadStageComment.length &&
              lead.leadStageComment[lead.leadStageComment.length - 1].tag &&
              lead.leadStageComment[lead.leadStageComment.length - 1].tag
                .name) ||
              ""
          )
        );

      this.setState({
        leads: this.props.leads.findLeadByFilter.lead,
        lastTags,
        totalLeads: this.props.leads.findLeadByFilter.totalLeads,
      });
    }

    if (
      this.props.colaborators !== prevProps.colaborators &&
      this.props.colaborators
    ) {
      const colaborators = this.props.colaborators;
      this.setState({ colaborators });
    }
  }

  handleChange = (name) => async (event) => {
    this.setState({ [name]: event.target.value });
  };

  errorParams() {
    const { allLeadsIdSelected, ownerTransferSelected } = this.state;

    let noParamns = [];
    let message = "Por favor, insira o(s) dado(s):";

    if (!allLeadsIdSelected.length) {
      noParamns.push(" lead");
    }

    if (!ownerTransferSelected) {
      noParamns.push(" orientador");
    }

    if (noParamns.length) {
      return message + noParamns + ".";
    }
    return "";
  }

  async handleSearch() {
    const {
      name,
      filterCourseOk,
      filterCampaignOk,
      filterMediaOk,
      filterStageOk,
    } = this.state;

    this.setState({
      offset: 0,
      totalLeads: 0,
      displayModalFilter: false,
    });

    this.props.leads.refetch({
      offset: 0,
      name: name.trim(),
      idCourse: filterCourseOk && filterCourseOk.id,
      idCampaign: filterCampaignOk && filterCampaignOk.id,
      idMedia: filterMediaOk && filterMediaOk.id,
      idStage: filterStageOk && filterStageOk.id,
    });
  }

  handleActive = (name, boolean) => {
    this.setState({ [name]: boolean });
  };

  handleDestroyLeads = async () => {
    const { allLeadsIdSelected } = this.state;
    const { role } = this.props.uiContext;

    if (!allLeadsIdSelected.length) {
      return Swal.fire({
        title: "Ops!",
        text: "Por favor, selecione o(s) lead(s) para ser desqualificado(s).",
        type: "error",
        confirmButtonText: "Ok",
      });
    }

    allLeadsIdSelected.map(
      async (idLead) =>
        await this.props.destroyLead({
          variables: {
            id: idLead,
            idManager: role,
          },
        })
    );

    let indexToRemove = [];
    let allLeads = this.state.leads;

    allLeadsIdSelected.map((removeId) =>
      indexToRemove.push(
        allLeads.findIndex((lead) => parseInt(lead.id) === removeId)
      )
    );

    allLeads.splice(indexToRemove, indexToRemove.length);

    this.setState({
      leads: allLeads,
      allLeadsIdSelected: [],
      isLeadConfiguration: false,
    });

    Swal.fire({
      position: "center",
      type: "success",
      title: "Leads desqualificados com sucesso.",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  getColorByFilter() {
    const {
      filterCourseOk,
      filterMediaOk,
      filterCampaignOk,
      filterStageOk,
    } = this.state;

    return (filterCourseOk && filterCourseOk.id) ||
      (filterMediaOk && filterMediaOk.id) ||
      (filterCampaignOk && filterCampaignOk.id) ||
      (filterStageOk && filterStageOk.id)
      ? "#3a72bf"
      : "#FF2244";
  }

  handlePagination(offset) {
    this.setState({ offset });

    this.props.leads.refetch({
      limit: 10,
      offset,
    });
  }

  handleCheck(idLead, boolean) {
    const { allLeadsIdSelected } = this.state;

    if (allLeadsIdSelected.includes(idLead)) {
      let indice = allLeadsIdSelected.indexOf(idLead);

      this.setState({
        allLeadsIdSelected: allLeadsIdSelected.filter((_, i) => i !== indice),
      });
    } else {
      this.setState({
        allLeadsIdSelected: [...allLeadsIdSelected, idLead],
      });
    }

    this.setState({ checked: boolean });
  }

  handleUpdateOperators = async () => {
    const { allLeadsIdSelected, ownerTransferSelected } = this.state;
    const { id, role } = this.props.uiContext;

    if (this.errorParams()) {
      return Swal.fire({
        title: "Ops!",
        text: this.errorParams(),
        type: "error",
        confirmButtonText: "Ok",
      });
    }

    await this.props.updateLeadOperators({
      variables: {
        idLead: allLeadsIdSelected,
        idOwner: ownerTransferSelected,
        idManager: id,
        role: role,
        param: "redistribution",
      },
    });

    this.setState({
      allLeadsIdSelected: [],
      ownerTransferSelected: 0,
      isLeadConfiguration: false,
      ownerOptionChoice: false,
    });

    Swal.fire({
      position: "center",
      type: "success",
      title: "Orientador atualizado com sucesso!",
      showConfirmButton: false,
      timer: 2000,
    });

    navigate("/leads");
  };

  async filterExportLead() {
    const {
      filterCourseOk,
      filterCampaignOk,
      filterMediaOk,
      filterStageOk,
    } = this.state;
    const { idFranchise } = this.props.uiContext;

    this.props.leadsForExcel.refetch({
      idFranchise,
      idCourse: filterCourseOk && filterCourseOk.id,
      idMedia: filterMediaOk && filterMediaOk.id,
      idCampaign: filterCampaignOk && filterCampaignOk.id,
      idStage: filterStageOk && filterStageOk.id,
    });

    this.setState({ displayModalExport: true, isNewExcelData: true });
  }

  renderLeads(leads) {
    const { checked, allLeadsIdSelected, isLeadConfiguration } = this.state;

    if (!leads.length) {
      return <Empty text="Nenhum lead foi encontrado." />;
    }

    return leads.map((lead, index) => (
      <SimpleCard
        onclick={() => this.handleCheck(parseInt(lead.id), !checked)}
        onClickLink={(e) => isLeadConfiguration && e.preventDefault()}
        link={`/profile/${lead.id}`}
        style={CardLeads}
        key={index}
        name={lead.name}
        cellphone={lead.cellphone}
        media={(lead.media && lead.media.name) || "desconhecido"}
        course={(lead.course && lead.course.name) || "desconhecido"}
        owner={(lead.owner && lead.owner.name) || ""}
        date={lead.createdAt && moment(lead.createdAt).format("DD/MM/YYYY")}
        tag={(lead.stageFranchise && lead.stageFranchise.stage.name) || ""}
        badge={
          lead.leadStageComment[lead.leadStageComment.length - 1] &&
          lead.leadStageComment[lead.leadStageComment.length - 1].tag &&
          lead.leadStageComment[lead.leadStageComment.length - 1].tag.name
        }
      >
        {isLeadConfiguration && (
          <CheckBoxCSS>
            <Checkbox
              icon={<CheckBoxOutlineBlankIconCSS />}
              checkedIcon={<CheckBoxIconCSS />}
              checked={
                allLeadsIdSelected.includes(parseInt(lead.id)) ? true : false
              }
            />
          </CheckBoxCSS>
        )}
      </SimpleCard>
    ));
  }

  handleChangeFilter = (list, filterListName, filterName) => (e) => {
    let characters = e.target.value;

    const filterItens =
      list &&
      list.filter(
        (item) =>
          removeAccents(escape(item.name.toLowerCase())).search(
            removeAccents(escape(characters.toLowerCase()))
          ) !== -1
      );

    function removeAccents(s) {
      return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    this.setState({
      [filterListName]: filterItens,
      [filterName]: e.target.value,
    });
  };

  handleOk = async (list, paramString, paramOk) => {
    const data = list.filter((item) => item.name === this.state[paramString]);

    if (data[0]) {
      this.setState({
        [paramOk]: { id: data[0].id, name: data[0].name },
        [paramString]: "",
      });
    }
  };

  handleFilterCancel = () => {
    this.setState({
      displayModalFilter: false,
      filterCourseOk: { id: 0, name: "" },
      filterMediaOk: { id: 0, name: "" },
      filterCampaignOk: { id: 0, name: "" },
      filterStageOk: { id: 0, name: "" },
    });
  };

  render() {
    const {
      name,
      displayModalFilter,
      displayModalExport,
      leads,
      totalLeads,
      offset,
      isLeadConfiguration,
      ownerOptionChoice,
      colaborators,
      anchorEl,
      courses,
      medias,
      stages,
      campaigns,
      filteredCoursesList,
      filteredMediasList,
      filteredCampaignsList,
      filteredStagesList,
      filterCourse,
      filterCourseOk,
      filterMedia,
      filterMediaOk,
      filterCampaign,
      filterCampaignOk,
      filterStage,
      filterStageOk,
    } = this.state;

    const { role } = this.props.uiContext;

    return (
      <LeadsCSS>
        <ModalFilter display={displayModalFilter}>
          <FilterField
            onOk={() =>
              this.handleOk(courses, "filterCourse", "filterCourseOk")
            }
            placeholder="Curso"
            value={filterCourse}
            onChange={this.handleChangeFilter(
              courses,
              "filteredCoursesList",
              "filterCourse"
            )}
            dataListComponent={
              <DataList>
                {filteredCoursesList &&
                  filteredCoursesList.map((course, index) => (
                    <DataListItem
                      key={index}
                      name={course.name}
                      onClick={() =>
                        this.setState({
                          filterCourse: course.name,

                          filteredCoursesList: [],
                        })
                      }
                    />
                  ))}
              </DataList>
            }
          >
            <FilterItem
              name={filterCourseOk && filterCourseOk.name}
              onClick={() =>
                this.setState({
                  filterCourseOk: { id: 0, name: "" },
                })
              }
            />
          </FilterField>

          <FilterField
            onOk={() => this.handleOk(medias, "filterMedia", "filterMediaOk")}
            placeholder="Midia"
            value={filterMedia}
            onChange={this.handleChangeFilter(
              medias,
              "filteredMediasList",
              "filterMedia"
            )}
            dataListComponent={
              <DataList>
                {filteredMediasList &&
                  filteredMediasList.map((media, index) => (
                    <DataListItem
                      key={index}
                      name={media.name}
                      onClick={() =>
                        this.setState({
                          filterMedia: media.name,
                          filteredMediasList: [],
                        })
                      }
                    />
                  ))}
              </DataList>
            }
          >
            <FilterItem
              name={filterMediaOk && filterMediaOk.name}
              onClick={() =>
                this.setState({
                  filterMediaOk: { id: 0, name: "" },
                })
              }
            />
          </FilterField>

          <FilterField
            onOk={() =>
              this.handleOk(campaigns, "filterCampaign", "filterCampaignOk")
            }
            placeholder="Campanhas"
            value={filterCampaign}
            onChange={this.handleChangeFilter(
              campaigns,
              "filteredCampaignsList",
              "filterCampaign"
            )}
            dataListComponent={
              <DataList>
                {filteredCampaignsList &&
                  filteredCampaignsList.map((campaign, index) => (
                    <DataListItem
                      key={index}
                      name={campaign.name}
                      onClick={() =>
                        this.setState({
                          filterCampaign: campaign.name,
                          filteredCampaignsList: [],
                        })
                      }
                    />
                  ))}
              </DataList>
            }
          >
            <FilterItem
              name={filterCampaignOk && filterCampaignOk.name}
              onClick={() =>
                this.setState({
                  filterCampaignOk: { id: 0, name: "" },
                })
              }
            />
          </FilterField>

          <FilterField
            onOk={() => this.handleOk(stages, "filterStage", "filterStageOk")}
            placeholder="Estagio"
            value={filterStage}
            onChange={this.handleChangeFilter(
              stages,
              "filteredStagesList",
              "filterStage"
            )}
            dataListComponent={
              <DataList>
                {filteredStagesList &&
                  filteredStagesList.map((stage, index) => (
                    <DataListItem
                      key={index}
                      name={stage.name}
                      onClick={() =>
                        this.setState({
                          filterStage: stage.name,
                          filteredStagesList: [],
                        })
                      }
                    />
                  ))}
              </DataList>
            }
          >
            <FilterItem
              name={filterStageOk && filterStageOk.name}
              onClick={() =>
                this.setState({
                  filterStageOk: { id: 0, name: "" },
                })
              }
            />
          </FilterField>

          <Button
            onClick={this.filterExportLead}
            link="#"
            width="100%"
            color="#FF2244"
          >
            Aplicar filtro para exportação
          </Button>

          <Button
            onClick={this.handleSearch}
            link="#"
            width="100%"
            color="#FF2244"
          >
            Filtrar
          </Button>

          <LinkForm
            link="#"
            onClick={this.handleFilterCancel}
            margin="1.5rem 0 0 0"
            text="Cancelar"
          />

          {displayModalExport && (
            <Modal>
              <FormGroup>
                <FormTitle text="Exportar leads" />

                {!this.props.leadsForExcel.loading ? (
                  <FormFields>
                    <Text>
                      {this.state.leadsForExcel.length
                        ? this.state.leadsForExcel.length
                        : 0}
                      - leads para exportação.
                    </Text>

                    {this.state.leadsForExcel.length > 0 && (
                      <ExcelFile
                        filename="leads"
                        element={
                          <ButtonExport>Exportar para .xlsx</ButtonExport>
                        }
                      >
                        <ExcelSheet
                          data={this.state.leadsForExcel || []}
                          name="Leads"
                        >
                          <ExcelColumn label="Nome" value="name" />
                          <ExcelColumn label="Celular" value="cellphone" />
                          <ExcelColumn label="Email" value="email" />
                          <ExcelColumn label="CodigoCurso" value="idCourse" />
                          <ExcelColumn label="CodigoMidia" value="idMedia" />
                          <ExcelColumn
                            label="CodigoCampanha"
                            value="idCampaign"
                          />
                          <ExcelColumn label="CPF" value="cpf" />
                          <ExcelColumn label="RG" value="rg" />
                          <ExcelColumn label="Endereco" value="address" />
                          <ExcelColumn label="Numero" value="number" />

                          <ExcelColumn label="Cidade" value="city" />
                          <ExcelColumn label="Estado" value="state" />
                          <ExcelColumn label="Bairro" value="neighborhood" />

                          <ExcelColumn label="Termometro" value="rate" />
                          <ExcelColumn label="Anotacoes" value="note" />
                          <ExcelColumn label="DataCadastro" value="createdAt" />
                        </ExcelSheet>
                      </ExcelFile>
                    )}

                    <LinkForm
                      disabledLink
                      onClick={() =>
                        this.setState({ displayModalExport: false })
                      }
                      margin="1.5rem 0 0 0"
                      text="Cancelar"
                    />
                  </FormFields>
                ) : (
                  <Loading />
                )}
              </FormGroup>
            </Modal>
          )}
        </ModalFilter>

        <ContentCSS>
          <Title
            align="center"
            size="2rem"
            color="black"
            text="Leads (contatos)"
          />

          {!isLeadConfiguration &&
            (role === "manager" || role === "franchiseOwner") && (
              <AlignSettingsButton>
                <Button
                  onClick={() =>
                    this.handleActive(
                      "isLeadConfiguration",
                      !isLeadConfiguration
                    )
                  }
                  margin="0px"
                  width="52px"
                  color="#FF2244"
                >
                  <SettingsIcon />
                </Button>
              </AlignSettingsButton>
            )}

          {!isLeadConfiguration && (
            <FieldsLeadsCSS>
              <Button
                margin="0 10px 0 0"
                onClick={() =>
                  this.handleActive("displayModalFilter", !displayModalFilter)
                }
                width="52px"
                color={this.getColorByFilter()}
              >
                <FilterIcon />
              </Button>

              <TextFieldFullCSS>
                <TextField
                  onChange={this.handleChange("name")}
                  value={name}
                  icon={SearchImage}
                  type="search"
                  label="Filtro rápido (nome ou whatsapp)"
                />
              </TextFieldFullCSS>
              <Button
                margin="10px"
                onClick={this.handleSearch}
                width="52px"
                color="#FF2244"
              >
                <SearchIcon />
              </Button>
            </FieldsLeadsCSS>
          )}

          {this.props.leads.loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {this.renderLeads(leads)}

              {totalLeads > 0 && (
                <PaginationCSS
                  limit={10}
                  offset={offset}
                  total={totalLeads}
                  onClick={(e, offset) => this.handlePagination(offset)}
                />
              )}
            </React.Fragment>
          )}

          {isLeadConfiguration && (
            <ModalHeader
              icon={
                <CloseIcon
                  onClick={() =>
                    this.setState({
                      isLeadConfiguration: false,
                      allLeadsIdSelected: [],
                    })
                  }
                  style={{ marginLeft: 12, marginRight: 32 }}
                />
              }
              size={this.state.allLeadsIdSelected.length}
            >
              <Tooltip title="Desqualificar lead(s) selecionado(s)">
                <DeleteIcon onClick={this.handleDestroyLeads} />
              </Tooltip>

              <MoreVerIcon
                onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                style={{ marginRight: 12 }}
              />

              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() =>
                    this.setState({ ownerOptionChoice: true, anchorEl: null })
                  }
                >
                  Trocar orientador
                </MenuItem>
              </Menu>
            </ModalHeader>
          )}

          {ownerOptionChoice && (
            <ModalHeader
              icon={
                <BackIcon
                  onClick={() => this.setState({ ownerOptionChoice: false })}
                  style={{ marginLeft: 12, marginRight: 32 }}
                />
              }
              size={this.state.allLeadsIdSelected.length}
            >
              <SelectOwnerCSS
                onChange={(e) =>
                  this.setState({
                    ownerTransferSelected: parseInt(e.target.value),
                  })
                }
              >
                <option value={0}> Selecione o orientador</option>
                {colaborators &&
                  colaborators.findAllOwnersByIdFranchise.map(
                    (colaborator, index) => (
                      <option key={index} value={colaborator.id}>
                        {colaborator.name}
                      </option>
                    )
                  )}
              </SelectOwnerCSS>

              <Tooltip title="Mudar orientador">
                <ChangeIconCSS onClick={this.handleUpdateOperators} />
              </Tooltip>
            </ModalHeader>
          )}
        </ContentCSS>
      </LeadsCSS>
    );
  }
}

const queryFindLeadByFilter = gql`
  query queryFindLeadByFilter(
    $name: String
    $idFranchise: ID!
    $idOwner: ID
    $limit: Int
    $offset: Int
    $idCourse: ID
    $idMedia: ID
    $idCampaign: ID
    $idStage: ID
  ) {
    findLeadByFilter(
      name: $name
      idFranchise: $idFranchise
      idOwner: $idOwner
      limit: $limit
      offset: $offset
      idCourse: $idCourse
      idMedia: $idMedia
      idCampaign: $idCampaign
      idStage: $idStage
    ) {
      id
      lead {
        id
        name
        cellphone
        idCourse
        idMedia
        idCampaign
        idOwner
        cpf
        rg
        address
        number
        email
        birth
        city
        state
        neighborhood
        rate
        note
        createdAt
        idStageFranchise
        media {
          id
          name
        }
        course {
          id
          name
        }
        leadStageComment {
          id
          tag {
            id
            name
          }
        }
        stageFranchise {
          id
          stage {
            id
            name
          }
        }
        owner {
          id
          name
        }
      }
      totalLeads
    }
  }
`;

const queryFindAllGenericCourses = gql`
  query queryFindAllGenericCourses {
    findAllGenericCourses {
      id
      name
    }
  }
`;

const queryFindAllMedias = gql`
  query queryFindAllMedias {
    findAllMedias {
      id
      name
    }
  }
`;

const queryFindAllFranchiseCampaignsByIdFranchise = gql`
  query queryFindAllFranchiseCampaignsByIdFranchise($idFranchise: ID) {
    findAllFranchiseCampaignsByIdFranchise(idFranchise: $idFranchise) {
      id
      name
    }
  }
`;

const queryFindAllStageFranchiseByIdFranchise = gql`
  query queryFindAllStageFranchiseByIdFranchise($idFranchise: ID!) {
    findAllStageFranchiseByIdFranchise(idFranchise: $idFranchise) {
      id
      idStage
      stage {
        id
        name
      }
      lead {
        id
      }
    }
  }
`;

const queryFindAllLeadsByFilterForExportExcel = gql`
  query queryFindAllLeadsByFilterForExportExcel(
    $idFranchise: ID
    $idOwner: ID
    $idCourse: ID
    $idMedia: ID
    $idCampaign: ID
    $idStage: ID
  ) {
    findAllLeadsByFilterForExportExcel(
      idFranchise: $idFranchise
      idOwner: $idOwner
      idCourse: $idCourse
      idMedia: $idMedia
      idCampaign: $idCampaign
      idStage: $idStage
    ) {
      id
      name
      cellphone
      email
      idCourse
      idMedia
      idCampaign
      cpf
      city
      state
      rg
      cep
      address
      number
      birth
      neighborhood
      rate
      note
      createdAt
    }
  }
`;

const queryFindAllOwnersByIdFranchise = gql`
  query queryFindAllOwnersByIdFranchise($idFranchise: ID) {
    findAllOwnersByIdFranchise(idFranchise: $idFranchise) {
      id
      idFranchise
      name
    }
  }
`;

const updateLeadOperators = gql`
  mutation updateLeadOperators(
    $idLead: [ID!]
    $idOwner: ID!
    $idManager: ID!
    $role: String!
    $param: String!
  ) {
    updateLeadOperators(
      idLead: $idLead
      idOwner: $idOwner
      idManager: $idManager
      role: $role
      param: $param
    )
  }
`;

const destroyLeadById = gql`
  mutation destroyLeadById($id: ID!, $idManager: ID!) {
    destroyLeadById(id: $id, idManager: $idManager)
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindLeadByFilter, {
    name: "leads",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        limit: 10,
        idOwner: props.uiContext.idOwner || "",
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllLeadsByFilterForExportExcel, {
    name: "leadsForExcel",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        idOwner: props.uiContext.idOwner || "",
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllFranchiseCampaignsByIdFranchise, {
    name: "allFranchiseCampaigns",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllGenericCourses, {
    name: "allCourses",
  }),
  graphql(queryFindAllMedias, {
    name: "allMedias",
  }),
  graphql(queryFindAllStageFranchiseByIdFranchise, {
    name: "allStageFranchise",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllOwnersByIdFranchise, {
    name: "colaborators",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(updateLeadOperators, { name: "updateLeadOperators" }),
  graphql(destroyLeadById, { name: "destroyLead" })
)(LeadsScreen);
