import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import SimpleCard from "../../components/SimpleCard";
import Checkbox from "@material-ui/core/Checkbox";
import SelectField from "../../components/SelectField";
import Button from "../../components/Button";
import Empty from "../../components/Empty";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import Loading from "./../../components/Loading";
import media from "styled-media-query";
import Swal from "sweetalert2";
import Pagination from "material-ui-flat-pagination";

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const NotifyScreen = styled.div`
  padding: 1.5rem;
  ${media.greaterThan("740px")`
    padding: 2rem 1rem;
    max-width: 790px;
    margin: 0 auto;
  `}
`;
const CheckBoxCSS = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  ${media.greaterThan("740px")`
    position: relative;
    right: initial;
    transform: initial;
  `}
`;

const GridNotify = styled.div`
  ${media.greaterThan("740px")`
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
  `}
`;

class Notify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      checked: false,
      owners: {},
      idOwner: 0,
      leadsWihoutOwner: [],
      totalLeads: 0,
      offSet: 0,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.colaborators.loading !== nextProps.colaborators.loading &&
      nextProps.colaborators
    ) {
      const owners = nextProps.colaborators;
      this.setState({ owners });
    }

    if (
      this.props.withoutOwner.loading !== nextProps.withoutOwner.loading &&
      nextProps.withoutOwner
    ) {
      const leadsWihoutOwner = nextProps.withoutOwner;

      this.setState({
        leadsWihoutOwner,
        totalLeads:
          nextProps.withoutOwner.findAllLeadsWithoutOwner.totalLeads || 0,
      });
    }
  }

  errorParams() {
    let noParamns = [];
    let message = "Por favor, insira o(s) dado(s):";

    if (!this.state.leads.length) {
      noParamns.push(" lead");
    }

    if (!this.state.idOwner) {
      noParamns.push(" orientador");
    }

    if (noParamns.length) {
      return message + noParamns + ".";
    }
    return "";
  }

  handlePagination(offset) {
    this.setState({ offset, leads: [] });

    this.props.withoutOwner.refetch({
      limit: 20,
      offset,
    });
  }

  async handleSave() {
    if (this.errorParams()) {
      return Swal.fire({
        title: "Ops!",
        text: this.errorParams(),
        type: "error",
        confirmButtonText: "Ok",
      });
    }
    const params = {
      idLead: this.state.leads,
      idOwner: parseInt(this.state.idOwner),
      idManager: this.props.uiContext.id,
      role: this.props.uiContext.role,
      param: "distribution",
    };

    await this.props.updateLeadOperators({
      variables: {
        ...params,
      },
    });

    this.setState({ leads: [], idOwner: 0 });

    window.location.replace("/notifications");
  }

  toggleCard(idLead) {
    if (this.state.leads.includes(idLead)) {
      let indice = this.state.leads.indexOf(idLead);
      delete this.state.leads[indice];
    } else {
      this.state.leads.push(idLead);
    }

    this.toggleCheck();
  }

  toggleCheck() {
    if (this.state.checked === true) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  renderCheckbox(idLead) {
    if (this.state.leads.includes(idLead)) {
      return (
        <CheckBoxCSS>
          <Checkbox checked={true} />
        </CheckBoxCSS>
      );
    } else {
      return (
        <CheckBoxCSS>
          <Checkbox checked={false} />
        </CheckBoxCSS>
      );
    }
  }

  renderActions(owners, leadsWihoutOwner) {
    if (
      leadsWihoutOwner.findAllLeadsWithoutOwner &&
      leadsWihoutOwner.findAllLeadsWithoutOwner.lead &&
      leadsWihoutOwner.findAllLeadsWithoutOwner.lead.length
    ) {
      return (
        <React.Fragment>
          <SelectField
            onChange={(e) =>
              this.setState({ idOwner: parseInt(e.target.value) })
            }
          >
            <option>Selecione um Operador</option>

            {owners.findAllOwnersByIdFranchise &&
              owners.findAllOwnersByIdFranchise.map((owner, index) => (
                <option key={index} value={owner.id}>
                  {owner.name}
                </option>
              ))}
          </SelectField>

          <Button
            onClick={this.handleSave}
            link="/notifications"
            width="100%"
            color="#FF2244"
          >
            Enviar para o operador
          </Button>
        </React.Fragment>
      );
    } else {
      return <Empty text="Nenhuma notificação no momento." />;
    }
  }

  renderLeads(leadsWihoutOwner) {
    return (
      <React.Fragment>
        {leadsWihoutOwner.findAllLeadsWithoutOwner &&
          leadsWihoutOwner.findAllLeadsWithoutOwner.lead.map((lead, index) => (
            <SimpleCard
              key={index}
              ref={this.SimpleCard}
              onClickLink={(e) => e.preventDefault()}
              onclick={() => this.toggleCard(parseInt(lead.id))}
              name={lead.name}
              media={(lead.media && lead.media.name) || "desconhecido"}
              course={(lead.course && lead.course.name) || "desconhecido"}
              campaign={(lead.campaign && lead.campaign.name) || "desconhecido"}
              date={lead.createdAt}
            >
              {this.renderCheckbox(parseInt(lead.id))}
            </SimpleCard>
          ))}
      </React.Fragment>
    );
  }

  renderNotifications(owners, leadsWihoutOwner) {
    const { offset, totalLeads } = this.state;

    return (
      <React.Fragment>
        <GridNotify>{this.renderLeads(leadsWihoutOwner)}</GridNotify>

        {totalLeads && (
          <PaginationStyled
            limit={20}
            offset={offset}
            total={totalLeads}
            onClick={(e, offset) => this.handlePagination(offset)}
          />
        )}

        {this.renderActions(owners, leadsWihoutOwner)}
      </React.Fragment>
    );
  }

  render() {
    let { owners, leadsWihoutOwner } = this.state;

    return (
      <React.Fragment>
        <NotifyScreen>
          <Title align="center" size="2rem" color="black" text="Notificações" />

          {this.props.withoutOwner.loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {this.renderNotifications(owners, leadsWihoutOwner)}
            </React.Fragment>
          )}
        </NotifyScreen>
      </React.Fragment>
    );
  }
}

const queryFindAllOwnersByIdFranchise = gql`
  query queryFindAllOwnersByIdFranchise($idFranchise: ID) {
    findAllOwnersByIdFranchise(idFranchise: $idFranchise) {
      id
      idFranchise
      name
    }
  }
`;

const queryFindAllLeadsWithoutOwner = gql`
  query queryFindAllLeadsWithoutOwner(
    $idFranchise: ID!
    $offset: Int
    $limit: Int
  ) {
    findAllLeadsWithoutOwner(
      idFranchise: $idFranchise
      offset: $offset
      limit: $limit
    ) {
      id
      lead {
        id
        name
        createdAt
        media {
          id
          name
        }
        course {
          id
          name
        }
        campaign {
          id
          name
        }
      }
      totalLeads
    }
  }
`;

const updateLeadOperators = gql`
  mutation updateLeadOperators(
    $idLead: [ID!]
    $idOwner: ID!
    $idManager: ID!
    $role: String!
    $param: String!
  ) {
    updateLeadOperators(
      idLead: $idLead
      idOwner: $idOwner
      idManager: $idManager
      role: $role
      param: $param
    )
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllOwnersByIdFranchise, {
    name: "colaborators",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(queryFindAllLeadsWithoutOwner, {
    name: "withoutOwner",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        offset: 0,
        limit: 20,
      },
      fetchPolicy: "cache-and-network",
    }),
  }),
  graphql(updateLeadOperators, { name: "updateLeadOperators" })
)(Notify);
