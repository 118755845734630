import React from "react";
import styled from "styled-components";
import Brand from "../../components/Brand";
import BrandImage from "../../assets/images/brand.png";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { Link } from "@reach/router";
import SplashImage from "./../../assets/images/splashScreen.png";

const SplashWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  background-color: "#000";
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  padding: 3rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const SplashTitle = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0 0rem;
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class SplashScreen extends React.Component {
  render() {
    return (
      <SplashWrapper image={SplashImage}>
        <div>
          <Brand image={BrandImage} />
        </div>
        <div>
          <SplashTitle>Bem-vindo ao CRM</SplashTitle>
          <Text
            align="center"
            color="white"
            content="Uma plataforma feita para aumentar seu controle e otimizar suas vendas."
          />
          <Link to="/login/">
            <Button width="100%" color="#FF2244" margin="0">
              Entrar
            </Button>
          </Link>
          <Link to="/about/">
            <Button width="100%" color="#444F63">
              O que é CRM?
            </Button>
          </Link>
        </div>
      </SplashWrapper>
    );
  }
}

export default SplashScreen;
