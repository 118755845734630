import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Calendar from "../../components/Calendar";
import SimpleCard from "../../components/SimpleCard";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { withUIContext } from "./../../contexts/UIContext";
import Loading from "./../../components/Loading";
import Empty from "./../../components/Empty";
import media from "styled-media-query";
import LabelSelect from "./../../components/LabelSelect";
import Event from "@material-ui/icons/Event";
import "moment/locale/pt-br";
import Pagination from "material-ui-flat-pagination";

const CalendarScreenCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width: 790px;
    margin: 0 auto;
  `}
`;

const AlignActionsDateCSS = styled.div`
  margin-bottom: 40px;
  ${media.greaterThan("620px")` 
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 40px;
  `}
`;

const CardaCalendar = {
  width: "100%"
};

const alignPagination = {
  margin: 20,
  justifyContent: "center",
  display: "flex"
};

class CalendarScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDate: moment().format("YYYY-MM-DD"),
      finalDate: moment()
        .add(3, "days")
        .format("YYYY-MM-DD"),
      schedules: {},
      totalSchedules: 0,
      offset: 0
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialDate, finalDate } = this.state;
    if (
      this.props.scheduleByPeriod.variables.initialDate !==
        this.state.initialDate ||
      this.props.scheduleByPeriod.variables.finalDate !== this.state.finalDate
    ) {
      this.props.scheduleByPeriod.refetch({ initialDate, finalDate });
    }

    if (
      this.props.scheduleByPeriod.loading !== prevProps.scheduleByPeriod.loading
    ) {
      const schedules = {
        ...this.props.scheduleByPeriod
      };

      this.setState({
        schedules,
        totalSchedules:
          this.props.scheduleByPeriod &&
          this.props.scheduleByPeriod.findScheduleByPeriod &&
          this.props.scheduleByPeriod.findScheduleByPeriod.totalSchedule
      });
    }

    if (this.props.uiContext.idOwner !== prevProps.uiContext.idOwner) {
      this.props.scheduleByPeriod.refetch({ initialDate, finalDate });
    }
  }

  handleDays(previousDatee, datee) {
    const date = moment(datee.date).format("DD");
    const previousDate =
      previousDatee && moment(previousDatee.date).format("DD");

    if (previousDate !== date) {
      return date;
    } else {
      return "";
    }
  }

  handleMonth(previousTimeStamp, timeStamp) {
    const month = moment(timeStamp.date).format("MMMM / YYYY");
    const previousMonth =
      previousTimeStamp &&
      moment(previousTimeStamp.date).format("MMMM / YYYY");

    if (month !== previousMonth) {
      return month;
    } else {
      return "";
    }
  }

  handleClick() {
    const { initialDate, finalDate } = this.state;
    this.props.scheduleByPeriod.refetch({ initialDate, finalDate });
  }

  handleChange = name => async event => {
    this.setState({ [name]: event.target.value });
  };

  handlePagination(offset) {
    this.setState({ offset });

    this.props.scheduleByPeriod.refetch({
      limit: 10,
      offset
    });
  }

  renderCalendar(schedules) {
    return (
      <React.Fragment>
        {schedules.map((schedule, index, prevSchedule) => (
          <Calendar
            key={index}
            month={this.handleMonth(prevSchedule[index - 1], schedule)}
            day={this.handleDays(prevSchedule[index - 1], schedule)}
          >
            <SimpleCard
              style={CardaCalendar}
              avatar={false}
              link="/schedule"
              state={parseInt(schedule.lead.id)}
              name={schedule.lead.name}
              media={
                (schedule.lead.media && schedule.lead.media.name) ||
                "desconhecido"
              }
              course={
                (schedule.lead.course && schedule.lead.course.name) ||
                "desconhecido"
              }
              horary={schedule.startTime}
              owner={schedule.lead.owner.name}
              tag={
                schedule.lead.stageFranchise &&
                schedule.lead.stageFranchise.stage &&
                schedule.lead.stageFranchise.stage.name
              }
              disabled={schedule.status === "Complete"}
              badge={
                schedule.lead.leadStageComment[
                  schedule.lead.leadStageComment.length - 1
                ] &&
                schedule.lead.leadStageComment[
                  schedule.lead.leadStageComment.length - 1
                ].tag &&
                schedule.lead.leadStageComment[
                  schedule.lead.leadStageComment.length - 1
                ].tag.name
              }
            />
          </Calendar>
        ))}
      </React.Fragment>
    );
  }

  getCalendar() {
    const schedules = this.state.schedules.findScheduleByPeriod;

    if (schedules && schedules.schedule.length) {
      return (
        <React.Fragment>
          {this.renderCalendar(schedules.schedule)}
        </React.Fragment>
      );
    } else {
      return <Empty text="Nenhum agendamento foi encontrado." />;
    }
  }

  render() {
    return (
      <CalendarScreenCSS>
        <Title align="center" size="2rem" color="black" text="Calendário" />
        <AlignActionsDateCSS>
          <LabelSelect
            type="date"
            dateBackground="#cecece"
            color="black"
            dateBorder="solid 1.5px #cecece"
            icon={<Event fontSize="small" />}
            text="Inicial:"
            value={this.state.initialDate}
            onChange={this.handleChange("initialDate")}
          />
          <LabelSelect
            type="date"
            dateBackground="#cecece"
            color="black"
            dateBorder="solid 1.5px #cecece"
            icon={<Event fontSize="small" />}
            text="Final:"
            value={this.state.finalDate}
            onChange={this.handleChange("finalDate")}
          />
        </AlignActionsDateCSS>

        {this.props.scheduleByPeriod.loading ? (
          <React.Fragment>
            <Loading />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.getCalendar()}
            <div style={alignPagination}>
              {this.state.totalSchedules > 0 && (
                <Pagination
                  limit={10}
                  offset={this.state.offset}
                  total={this.state.totalSchedules}
                  onClick={(e, offset) => this.handlePagination(offset)}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </CalendarScreenCSS>
    );
  }
}

const queryFindScheduleByPeriod = gql`
  query queryFindScheduleByPeriod(
    $idFranchise: ID!
    $idOwner: ID
    $initialDate: String
    $finalDate: String
    $limit: Int
    $offset: Int
  ) {
    findScheduleByPeriod(
      idFranchise: $idFranchise
      idOwner: $idOwner
      initialDate: $initialDate
      finalDate: $finalDate
      limit: $limit
      offset: $offset
    ) {
      id
      schedule {
        id
        count
        date
        startTime
        status
        lead {
          id
          name
          idMedia
          media {
            id
            name
          }
          course {
            id
            name
          }
          leadStageComment {
            id
            tag {
              id
              name
            }
          }
          stageFranchise {
            id
            stage {
              id
              name
            }
          }
          owner {
            id
            name
          }
        }
      }
      totalSchedule
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindScheduleByPeriod, {
    name: "scheduleByPeriod",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        idOwner: props.uiContext.idOwner || "",
        initialDate: moment().format("YYYY-MM-DD"),
        finalDate: moment()
          .add(3, "days")
          .format("YYYY-MM-DD"),
        limit: 10,
        offset: 0
      },
      fetchPolicy: "cache-and-network"
    })
  })
)(CalendarScreen);
