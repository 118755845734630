import React from "react";
import media from "styled-media-query";
import styled from "styled-components";
import Title from "./../Title";

const ModalFilterCSS = styled.div`
  display: ${props => props.display};
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  padding: 3rem 25%;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 9;
  overflow-y: scroll;
  ${media.lessThan("480px")`
    padding: 3rem 1rem;
  `}
`;

export const ModalFilter = props => {
  return (
    <ModalFilterCSS display={props.display === true ? "block" : "none"}>
      <Title align="center" size="2rem" color="black" text="Filtro" />
      {props.children}
    </ModalFilterCSS>
  );
};

export default ModalFilter;
