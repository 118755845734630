import React from "react";
import styled from "styled-components";
import FormGroup from "../../components/FormGroup";
import FormTitle from "../../components/FormTitle";
import FormFields from "../../components/FormFields";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import axios from "axios";
import SelectField from "./../../components/SelectField";

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorCSS = styled.div`
  display: block;
  padding: 1rem;
  border-radius: 7px;
  background-color: #ff224421;
  margin: 1rem 0;
  font-size: 0.8rem;
  text-align: center;
`;

const FormGroupCSS = styled.div`
  max-width: 530px;
  margin: 0 auto;
`;

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      franchises: [],
      password: "",
      message: "",
      data: {},
      isFranchiseOwner: false,
      isLoading: true,
      idFranchise: 0
    };

    this.handleLogin = this.handleLogin.bind(this);
    localStorage.setItem("token", null);
  }

  async componentWillMount() {
    const token = localStorage.getItem("x-access-token");

    const serverUrl = process.env.REACT_APP_AUTH_VALIDATE;
    if (token !== "null") {
      try {
        await axios.get(`${serverUrl}`, {
          headers: {
            "x-access-token": token
          }
        });
        window.location.replace("/home");
      } catch (err) {
        console.log(err);
        localStorage.setItem("token", null);
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.data.id !== prevState.data.id && this.state.data.id) {
      const { data } = await this.fetchData(this.state.data.id);

      this.setState({ franchises: data, isLoading: false });
    }
  }

  async fetchData(id) {
    return await axios.get(
      `${process.env.REACT_APP_ERP_API}/crm/franchises/owner/${id}`
    );
  }

  handleChange = name => async event => {
    this.setState({ [name]: event.target.value });
  };

  handleClick = async () => {
    const serverUrl = process.env.REACT_APP_AUTH_LOGIN;
    const { idFranchise, data } = this.state;

    if (!idFranchise) {
      return alert("Por favor, escolha uma franquia");
    }

    try {
      const response = await axios.post(`${serverUrl}/login`, {
        data: { ...data },
        idFranchise
      });

      localStorage.setItem("token", response.data.token);
      window.location.replace("/home");
    } catch (err) {
      console.log(err);
    }
  };

  async handleLogin(e) {
    const serverUrl = process.env.REACT_APP_AUTH_LOGIN;
    let res = {};

    try {
      res = await axios.post(`${serverUrl}/auth`, {
        username: this.state.username,
        password: this.state.password
      });

      if (res.data.role !== "franchiseOwner") {
        const data = await axios.post(`${serverUrl}/login`, {
          data: { ...res.data }
        });

        localStorage.setItem("token", data.data.token);
        window.location.replace("/home");
      } else {
        this.setState({ data: res.data, isFranchiseOwner: true });
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        this.setState({ message: "Usuário ou senha incorreto." });
      } else if (err.response && err.response.status === 403) {
        this.setState({ message: "Você não tem acesso a esse produto" });
      } else {
        this.setState({ message: "[2] Ocorreu um erro na autenticação." });
      }
    }
  }

  getMessage(message) {
    return message !== "" ? <ErrorCSS>{message}</ErrorCSS> : "";
  }

  render() {
    const { isFranchiseOwner, idFranchise, franchises } = this.state;
    return (
      <LoginWrapper>
        <div>
          <form>
            <FormGroupCSS>
              <FormGroup>
                <FormTitle
                  text={isFranchiseOwner ? "Selecione a franquia" : "Entrar"}
                />

                <FormFields>
                  {this.getMessage(this.state.message)}

                  {isFranchiseOwner ? (
                    <SelectField
                      value={idFranchise}
                      onChange={this.handleChange("idFranchise")}
                    >
                      <option value="">Franquia </option>

                      {franchises &&
                        franchises.map((franchise, index) => (
                          <option
                            key={index}
                            selected={idFranchise === franchise.id}
                            value={franchise.id}
                          >
                            {franchise.name}
                          </option>
                        ))}
                    </SelectField>
                  ) : (
                    <React.Fragment>
                      <TextField
                        onKeyUp={e =>
                          e.key === "Enter"  &&  isFranchiseOwner ? this.handleLogin() : null
                        }
                        label="Login"
                        onChange={e =>
                          this.setState({ username: e.target.value })
                        }
                      />
                      <TextField
                        onKeyUp={e =>
                          e.key === "Enter" ? this.handleLogin() : null
                        }
                        label="Senha"
                        type="password"
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </React.Fragment>
                  )}
                  <Button
                    margin=".5rem 0"
                    onClick={
                      isFranchiseOwner ? this.handleClick : this.handleLogin
                    }
                    width="100%"
                    color="#FF2244"
                  >
                    Continue
                  </Button>
                </FormFields>
              </FormGroup>
            </FormGroupCSS>
          </form>
        </div>
      </LoginWrapper>
    );
  }
}

export default LoginScreen;
