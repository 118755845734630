import React from "react";
import styled from 'styled-components';

const FormGroupCSS = styled.div`
  width: 100%;
`;

class FormGroup extends React.Component {

    render() {
        
        return (
            <FormGroupCSS>
                { this.props.children }
            </FormGroupCSS>
        );
    }
}

export default FormGroup;