import React from "react";
import styled from "styled-components";

const FilterFieldCSS = styled.div`
  padding: 0 0 2rem 0;
  border-bottom: solid 1px #cecece;
`;

const FilterFieldInputCSS = styled.div`
  position: relative;
  min-width: 250px;
`;

const TextFieldCss = styled.input`
  border: none;
  outline: none;
  height: 45px;
  width: 100%;
  display: block;
  border-radius: 10px;
  border: solid 1px #cecece;
  margin: 1.2rem 0;
  font-size: 0.8rem;
  position: ${props => props.position || "inherit"};
  top: ${props => props.top || "inherit"};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding: 1rem 5rem 1rem 1rem;
  background: #ffffff;
`;

const FilterFieldInputButtonCSS = styled.button`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background: #ff2244;
  color: #fff;
  outline: none;
`;

const FilterItemsCSS = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterField = props => {
  return (
    <FilterFieldCSS>
      <FilterFieldInputCSS>
        <TextFieldCss
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
        />
        <FilterFieldInputButtonCSS onClick={props.onOk}>
          OK
        </FilterFieldInputButtonCSS>
        {props.value && props.value.length >= 3 && props.dataListComponent}
      </FilterFieldInputCSS>
      <FilterItemsCSS>{props.children}</FilterItemsCSS>
    </FilterFieldCSS>
  );
};

export default FilterField;
