import React, { Component } from "react";
import MetroMenu from "../../components/MetroMenu";
import MetroMenuItem from "../../components/MetroMenuItem";
import Empty from "../../components/Empty";
import Today from "@material-ui/icons/Today";
import ViewWeek from "@material-ui/icons/ViewWeek";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Equalizer from "@material-ui/icons/Equalizer";
import Settings from "@material-ui/icons/Settings";
import Reminder from "../../components/Reminder";
import SimpleCard from "../../components/SimpleCard";
import Loading from "./../../components/Loading";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import moment from "moment";
import "moment/locale/pt-br";

class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedules: {},
      role: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.uiContext) {
      this.setState({ role: this.props.uiContext.role });
    }

    if (
      this.props.schedulesToday.loading !== nextProps.schedulesToday.loading &&
      nextProps.schedulesToday
    ) {
      this.setState({ schedules: nextProps.schedulesToday });
    }
  }

  renderSimpleCard() {
    if (typeof this.state.schedules.findAllSchedulesActualDay !== "undefined") {
      let check = this.state.schedules.findAllSchedulesActualDay.length > 0;

      if (check === true) {
        return this.state.schedules.findAllSchedulesActualDay.map(
          (schedule, index) => (
            <SimpleCard
              key={index}
              link={`/profile/${schedule.lead && schedule.lead.id}`}
              name={schedule.lead && schedule.lead.name}
              horary={schedule.startTime && schedule.startTime}
              course={
                (schedule.lead &&
                  schedule.lead.course &&
                  schedule.lead.course.name) ||
                "desconhecido"
              }
            />
          )
        );
      } else {
        return <Empty color="#fff" text="Nenhum lead agendado para hoje" />;
      }
    }
  }

  render() {
    const dateToday = moment().format("dddd, DD - MMMM");
    const { role } = this.state;

    return (
      <React.Fragment>
        {this.props.uiContext.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <MetroMenu>
              <MetroMenuItem
                color="#DC2C3B"
                text="Agendamentos"
                icon={<Today fontSize="large" />}
                link="/calendar/"
              />
              <MetroMenuItem
                color="#D01B36"
                text="Pipeline"
                icon={<ViewWeek fontSize="large" />}
                link="/pipeline/"
              />
              <MetroMenuItem
                color="#B9162F"
                text="Contatos"
                icon={<Person fontSize="large" />}
                link="/leads/"
              />
              <MetroMenuItem
                color="#A3142A"
                text="Novo Contato"
                icon={<PersonAdd fontSize="large" />}
                link="/new/"
              />
              {(role === "manager" || role === "franchiseOwner") && (
                <React.Fragment>
                  <MetroMenuItem
                    color="#961227"
                    text="Relatórios"
                    icon={<Equalizer fontSize="large" />}
                    link="/reports/"
                  />
                  <MetroMenuItem
                    color="#881123"
                    text="Configurações"
                    icon={<Settings fontSize="large" />}
                    link="/settings"
                  />
                </React.Fragment>
              )}
            </MetroMenu>
            <Reminder day={dateToday}>{this.renderSimpleCard()}</Reminder>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const queryFindAllSchedulesActualDay = gql`
  query queryFindAllSchedulesActualDay($idFranchise: ID!, $idOwner: ID) {
    findAllSchedulesActualDay(idFranchise: $idFranchise, idOwner: $idOwner) {
      id
      startTime
      lead {
        id
        name
        course {
          id
          name
        }
      }
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllSchedulesActualDay, {
    name: "schedulesToday",
    options: (props) => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0,
        idOwner: props.uiContext.idOwner || "",
      },
      fetchPolicy: "cache-and-network",
    }),
  })
)(HomeScreen);
