import React from "react";
import { ApolloProvider } from "react-apollo";
import { UIProvider } from "./contexts/UIContext";
import client from "./apollo/graphClient";
import Drawer from "./components/Drawer";
import { RouterLogin } from "./routes";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        {window.location.pathname === "/login" ||
        window.location.pathname === "/" ||
        window.location.pathname === "/about/" ? (
          <RouterLogin />
        ) : (
          <UIProvider>
            <ApolloProvider client={client}>
              <Drawer {...this.props} />
            </ApolloProvider>
          </UIProvider>
        )}
      </React.Fragment>
    );
  }
}

export default App;
