import React from "react";
import { withUIContext } from "./../../contexts/UIContext";
import styled from "styled-components";
import SelectField from "./../../components/SelectField";
import SimpleCard from "../../components/SimpleCard";
import Empty from "../../components/Empty";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import Loading from "./../../components/Loading";
import media from "styled-media-query";
import Pagination from "material-ui-flat-pagination";
import moment from "moment";

const PipelineCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

const CardPipeline = {
  width: "100%",
  margin: "0 0 1rem 0"
};

const alignPagination = {
  margin: 20,
  justifyContent: "center",
  display: "flex"
};

class Pipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      stages: {},
      idStages: [],
      leads: [],
      totalLeads: 0,
      offset: 0
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({ value: parseInt(event.target.value) });

    this.props.leadByStageFranchise.refetch({
      idStageFranchise: event.target.value
    });
  };

  handlePagination(offset) {
    this.setState({ offset });

    this.props.leadByStageFranchise.refetch({
      limit: 10,
      offset
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.allStagesFranchise.loading !==
        prevProps.allStagesFranchise.loading &&
      this.props.allStagesFranchise.findAllStageFranchiseByIdFranchise &&
      this.props.allStagesFranchise.findAllStageFranchiseByIdFranchise.length
    ) {
      const stages = this.props.allStagesFranchise;
      this.setState({ stages });
    }
    if (this.props.uiContext !== prevProps.uiContext) {
      this.props.leadByStageFranchise.refetch({
        idStageFranchise: this.state.value
      });
    }

    if (
      this.props.leadByStageFranchise.loading !==
        prevProps.leadByStageFranchise.loading &&
      this.props.leadByStageFranchise.findAllLeadsByStageFranchise
    ) {
      this.setState({
        leads: this.props.leadByStageFranchise.findAllLeadsByStageFranchise
          .lead,
        totalLeads: this.props.leadByStageFranchise.findAllLeadsByStageFranchise
          .totalLeads
      });
    }
  }

  renderSimpleCard() {
    const allStageFranchise = this.state.leads;

    const check = allStageFranchise && allStageFranchise.length > 0;

    if (check === true) {
      return (
        <React.Fragment>
          {allStageFranchise.map((item, index) => (
            <SimpleCard
              style={CardPipeline}
              key={index}
              link={`/profile/${item.id}`}
              name={item.name && item.name}
              media={(item.media && item.media.name) || "desconhecido"}
              course={(item.course && item.course.name) || "desconhecido"}
              owner={item.owner && item.owner.name}
              badge={
                item.lastStageComment &&
                item.lastStageComment.tag &&
                item.lastStageComment.tag.name
              }
              date={
                item.createdAt &&
                moment(item.createdAt).format("DD/MM/YYYY HH:mm")
              }
            />
          ))}
        </React.Fragment>
      );
    } else {
      if (this.state.value === 0) {
        return <Empty text="Selecione a pipeline que você deseja exibir" />;
      } else {
        return <Empty text="Nenhum lead foi encontrado na pipeline" />;
      }
    }
  }

  render() {
    const { offset, totalLeads } = this.state;

    return (
      <PipelineCSS>
        <SelectField value={this.state.value} onChange={this.handleChange}>
          <option>Selecione a pipeline</option>

          {this.state.stages.findAllStageFranchiseByIdFranchise &&
            this.state.stages.findAllStageFranchiseByIdFranchise &&
            this.state.stages.findAllStageFranchiseByIdFranchise.map(
              (item, index) => (
                <option key={index} value={item.id}>
                  {item.stage.name}
                </option>
              )
            )}
        </SelectField>

        {this.props.leadByStageFranchise.loading ||
        this.props.allStagesFranchise.loading ? (
          <React.Fragment>
            <Loading />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.renderSimpleCard()}
            <div style={alignPagination}>
              {this.state.totalLeads > 0 && (
                <Pagination
                  limit={10}
                  offset={offset}
                  total={totalLeads}
                  onClick={(e, offset) => this.handlePagination(offset)}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </PipelineCSS>
    );
  }
}

const queryFindAllStageFranchiseByIdFranchise = gql`
  query queryFindAllStageFranchiseByIdFranchise($idFranchise: ID!) {
    findAllStageFranchiseByIdFranchise(idFranchise: $idFranchise) {
      id
      stage {
        id
        name
      }
    }
  }
`;

const queryFindAllLeadsByStageFranchise = gql`
  query queryFindAllLeadsByStageFranchise(
    $idStageFranchise: ID!
    $idOwner: ID
    $limit: Int
    $offset: Int
  ) {
    findAllLeadsByStageFranchise(
      idStageFranchise: $idStageFranchise
      idOwner: $idOwner
      limit: $limit
      offset: $offset
    ) {
      id
      lead {
        id
        idOwner
        name
        idMedia
        createdAt
        media {
          id
          name
        }
        course {
          id
          name
        }
        lastStageComment {
          id
          tag {
            id
            name
          }
        }
        owner {
          id
          name
        }
        stageFranchise {
          id
        }
      }
      totalLeads
    }
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllStageFranchiseByIdFranchise, {
    name: "allStagesFranchise",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),
  graphql(queryFindAllLeadsByStageFranchise, {
    name: "leadByStageFranchise",
    options: props => ({
      variables: {
        idStageFranchise: 0,
        idOwner: props.uiContext.idOwner || "",
        limit: 10,
        offset: 0
      },
      fetchPolicy: "cache-and-network"
    })
  })
)(Pipeline);
