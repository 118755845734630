import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const MetaInfoCSS = styled.div`
  font-size: 0.8rem;
  line-height: 1.5;
  color: ${props => props.color || "#000000"};
`;

class MetaInfo extends React.Component {
  render() {
    const { label, value, color } = this.props;
    return (
      <MetaInfoCSS color={color}>
        {label}: {value}
      </MetaInfoCSS>
    );
  }
}

MetaInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string
};

export default MetaInfo;
