import React from "react";
import styled from "styled-components";

const BadgeCSS = styled.p`
  background: #4d97fd;
  display: table;
  color: #fff;
  margin: ${props => props.margin || ".5rem 0"};
  font-weight: 600;
  font-size: .7rem;
  padding: .5rem;
  border-radius: 26px;
`;

class Badge extends React.Component {
  render() {
    const { text, margin } = this.props;
    return (
      <BadgeCSS margin={margin}>{text}</BadgeCSS>
    );
  }
}

export default Badge;