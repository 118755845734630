import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Setting from "../../components/Setting";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/Button";
import LinkForm from "../../components/LinkForm";
import media from "styled-media-query";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withUIContext } from "./../../contexts/UIContext";
import Loading from "./../../components/Loading";
import Swal from "sweetalert2";

const SettingsCSS = styled.div`
  padding: 2rem 1rem;
  ${media.greaterThan("740px")`
    max-width:790px;
    margin:0 auto;
  `}
`;

const SettingsText = styled.p`
  line-height: 1.5;
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;

class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      onSave: false,
      initialAllStageFranchiseIds: [],
      allStageFranchiseIds: [],
      allStageFranchise: [],
      allStages: [],
      allStagesMerged: [],
      isLoading: false
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.allStageFranchise !== nextProps.allStageFranchise &&
      nextProps.allStageFranchise.findAllStageFranchiseByIdFranchise
    ) {
      const allStageFranchise =
        nextProps.allStageFranchise.findAllStageFranchiseByIdFranchise;

      const allStageFranchiseIds = allStageFranchise.map(item =>
        parseInt(item.idStage)
      );

      const allStagesMerged = this.mergeStageFranchiseToStage(
        allStageFranchise,
        nextProps.allStages.findAllStages
      );

      this.setState({
        allStageFranchiseIds,
        initialAllStageFranchiseIds: allStageFranchiseIds,
        allStagesMerged
      });
    }

    if (
      this.props.allStages !== nextProps.allStages &&
      nextProps.allStages.findAllStages
    ) {
      const allStages = nextProps.allStages.findAllStages;

      this.setState({ allStages });
    }
  }

  mergeStageFranchiseToStage(stageFranchises, stages) {
    return (
      stages &&
      stages.map(stage => {
        for (let stageFranchise of stageFranchises) {
          if (stageFranchise.idStage === stage.id) {
            return {
              id: stage.id,
              name: stage.name,
              checked: true,
              hasLead:
                stage.name === "Novo lead" || !!stageFranchise.lead.length
            };
          }
        }
        return {
          id: stage.id,
          name: stage.name,
          checked: false,
          hasLead: stage.name === "Novo lead" || false
        };
      })
    );
  }

  componentWillUpdate(nextProps, nextState) {
    let addedStage = nextState.allStageFranchiseIds.filter(
      item => !nextState.initialAllStageFranchiseIds.includes(item)
    );

    let removedStage = nextState.initialAllStageFranchiseIds.filter(
      item => !nextState.allStageFranchiseIds.includes(item)
    );

    if (
      (addedStage.length && !this.state.onSave) ||
      (removedStage.length && !this.state.onSave)
    ) {
      this.setState({ onSave: true });
    } else if (
      !addedStage.length &&
      this.state.onSave &&
      !removedStage.length &&
      this.state.onSave
    ) {
      this.setState({ onSave: false });
    }
  }

  toggleSettings(idSettings, hasLead) {
    const { allStageFranchiseIds } = this.state;

    if (hasLead) {
      Swal.fire({
        title: "Ops!",
        text:
          "Não foi possível desmarcar este estágio pois há leads vínculados. Desvincule os leads desse estágio para remover do pipeline.",
        type: "error",
        confirmButtonText: "Ok"
      });
    } else if (allStageFranchiseIds.includes(idSettings)) {
      let indice = allStageFranchiseIds.indexOf(idSettings);

      this.setState({
        allStageFranchiseIds: allStageFranchiseIds.filter(
          (_, i) => i !== indice
        )
      });
    } else {
      this.setState({
        allStageFranchiseIds: [...allStageFranchiseIds, idSettings]
      });
    }

    this.toggleCheck();
  }

  toggleCheck() {
    if (this.state.checked === true) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  renderCheckbox(idSettings) {
    if (this.state.allStageFranchiseIds.includes(idSettings)) {
      return <Checkbox checked={true} />;
    } else {
      return <Checkbox checked={false} />;
    }
  }

  async handleSave() {
    const { idFranchise } = this.props.uiContext;

    this.setState({ isLoading: true });
    const { initialAllStageFranchiseIds, allStageFranchiseIds } = this.state;

    let removeItens = initialAllStageFranchiseIds.filter(
      item => !allStageFranchiseIds.includes(item)
    );
    let addItens = allStageFranchiseIds.filter(
      item => !initialAllStageFranchiseIds.includes(item)
    );

    const params = {
      idFranchise,
      removeStage: removeItens,
      addStage: addItens
    };

    await this.props.toggleStageFranchise({ variables: { ...params } });

    this.setState({
      isLoading: false,
      onSave: false
    });

    this.props.allStageFranchise.refetch();
  }

  render() {
    const { allStagesMerged } = this.state;

    return (
      <React.Fragment>
        <SettingsCSS>
          <Title
            align="center"
            size="2rem"
            color="black"
            text="Configurações"
          />
          <SubTitle
            align="center"
            size="1.5rem"
            color="black"
            text="Pipeline"
          />
          <SettingsText>
            Selecione abaixo quais os itens você não deseja exibir na pipeline.
          </SettingsText>
          <SettingsText>
            Caso um estágio da pipeline esteja vinculado à algum lead, você não
            poderá desmarcar, caso haja necessidade, retire o vínculo de todos
            os leads que esteja com o estágio que você deseja desmarcar.
          </SettingsText>
          {this.props.allStageFranchise.loading || this.state.isLoading ? (
            <Loading />
          ) : (
            allStagesMerged &&
            allStagesMerged.map((stage, index) => (
              <Setting
                key={index}
                onclick={() =>
                  this.toggleSettings(parseInt(stage.id), stage.hasLead)
                }
                label={stage.name}
              >
                {this.renderCheckbox(parseInt(stage.id))}
              </Setting>
            ))
          )}

          {this.state.onSave === true && (
            <React.Fragment>
              <Button onClick={this.handleSave} width="100%" color="#FF2244">
                Salvar
              </Button>
              <LinkForm
                disabledLink
                onClick={e => this.props.allStageFranchise.refetch()}
                margin="1.5rem 0 0 0"
                text="Restaurar Configurações"
              />
            </React.Fragment>
          )}
        </SettingsCSS>
      </React.Fragment>
    );
  }
}

const queryFindAllStageFranchiseByIdFranchise = gql`
  query queryFindAllStageFranchiseByIdFranchise($idFranchise: ID!) {
    findAllStageFranchiseByIdFranchise(idFranchise: $idFranchise) {
      id
      idStage
      stage {
        id
        name
      }
      lead {
        id
      }
    }
  }
`;

const queryFindAllStages = gql`
  query queryFindAllStages {
    findAllStages {
      id
      name
    }
  }
`;

const toggleStageFranchise = gql`
  mutation toggleStageFranchise(
    $idFranchise: ID
    $removeStage: [ID]
    $addStage: [ID]
  ) {
    toggleStageFranchise(
      idFranchise: $idFranchise
      removeStage: $removeStage
      addStage: $addStage
    )
  }
`;

export default compose(
  withUIContext,
  graphql(queryFindAllStageFranchiseByIdFranchise, {
    name: "allStageFranchise",
    options: props => ({
      variables: {
        idFranchise: props.uiContext.idFranchise || 0
      },
      fetchPolicy: "cache-and-network"
    })
  }),
  graphql(queryFindAllStages, { name: "allStages" }),
  graphql(toggleStageFranchise, { name: "toggleStageFranchise" })
)(SettingsScreen);
