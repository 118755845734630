import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TagCSS = styled.div`
  font-size: 0.8rem;
  line-height: 1.5;
  border-left: solid 3px ${props => props.color || "#FF2244"};
  display: table;
  padding: 0rem 0.5rem;
  color: ${props => props.color || "#FF2244"};
`;

class Tag extends React.Component {
  render() {
    const { text, color } = this.props;
    return <TagCSS color={color}>{text}</TagCSS>;
  }
}

Tag.propTypes = {
  text: PropTypes.string
};

export default Tag;
