import React from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Text from "../../components/Text";
import Dots from "../../components/Dots";
import Button from "../../components/Button";
import IconArrowForward from "@material-ui/icons/ArrowForward";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import IconDone from "@material-ui/icons/Done";
import { Link } from "@reach/router";
import media from "styled-media-query";

const SlideWrapper = styled.div`
  ${media.greaterThan("740px")`
    max-width: 660px;
    margin: 0 auto;
  `}
`;

const SlideItem = styled.div`
  
`;

const SlideControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SlideImage = styled.img`
  display: block;
  max-width: 100%;
`;

const ButtonsCSS = styled.div`
  display: flex;
`;

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      totalSlides: Object.keys(this.props.slide).length
    };

    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
  }

  nextSlide() {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide < Object.keys(this.props.slide).length) {
      this.setState({
        currentSlide: nextSlide
      });
    }
  }

  prevSlide() {
    let prevSlide = this.state.currentSlide - 1;

    if (prevSlide >= 0) {
      this.setState({
        currentSlide: prevSlide
      });
    }
  }

  isLastSlide() {
    return this.state.currentSlide === this.state.totalSlides - 1
      ? true
      : false;
  }

  isFirstSlide() {
    return this.state.currentSlide === 0 ? true : false;
  }

  render() {
    return (
      <SlideWrapper>
        <SlideItem>
          <SlideImage src={this.props.slide[this.state.currentSlide].image} />
          <Title text={this.props.slide[this.state.currentSlide].title} />
          <Text content={this.props.slide[this.state.currentSlide].text} />
        </SlideItem>

        <SlideControl>
          <div>
            <Dots
              total={Object.keys(this.props.slide).length}
              current={this.state.currentSlide}
            />
          </div>

          <ButtonsCSS>
            <Link to={this.isFirstSlide() ? "/" : "/about/"}>
              <Button margin="1rem .5rem" onClick={() => this.prevSlide()} color="#454F63">
                <IconArrowBack />
              </Button>
            </Link>
            <Link to={this.isLastSlide() ? "/" : "/about/"}>
              <Button
                onClick={() => this.nextSlide()}
                color={this.isLastSlide() ? "#4CAF50" : "#FF2244"}
              
              >
                {this.isLastSlide() ? <IconDone /> : <IconArrowForward />}
              </Button>
            </Link>
          </ButtonsCSS>
        </SlideControl>
      </SlideWrapper>
    );
  }
}

export default Slide;
